<template>
  <div class="container container--nav">
    <div class="nav__items">
      <div class="nav__item item-wpl-logo">
        <img src="@/assets/images/whirlpool.svg" />
      </div>
      <div class="nav__item"><img src="@/assets/images/hotpoint.svg" /></div>
      <div class="nav__item"><img src="@/assets/images/baucknecht.svg" /></div>
      <div class="nav__item"><img src="@/assets/images/indesit.svg" /></div>
      <div class="nav__item"><img src="@/assets/images/privileg.svg" /></div>
      <div class="nav__item ignis">
        <img src="@/assets/images/ignis.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VNav",
};
</script>

<style scoped lang="scss">
.container--nav {
  background-color: $nav-background;
  margin: 0px;
  color: white;
  display: flex;
  justify-content: center;
}

.nav__items {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  padding-inline-start: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  align-items: center;
}

.nav__item {
  padding: 0 20px 0 20px;
  margin: 10px 5px 0 5px;
  align-items: center;

  &.item-wpl-logo {
    margin-top: 11px;
  }

  img {
    width: 65px;
  }
}
.nav__item.ignis {
  img {
    height: 15px;
    width: auto;
  }
}

@include desktop {
  .nav__item {
    img {
      width: 100px;
    }
  }
  .nav__item.ignis {
    img {
      height: 20px;
      width: auto;
    }
  }
}
</style>
