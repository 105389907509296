import { createStore } from 'vuex'
import axios from "axios";
import countryMng from "@/helper/country";
import domainCheck from '@/helper/domain';

const state = {
    isLoading: false,
    envMapping: {
        'development': 'DEV',
        'staging': 'STAGING',
        'production': 'PROD',
    },
    labels: {
        meta_description: 'Discover and activate the free warranty of your product',
        hero_first: '',
        hero_second: '',
        hero_third: '',
        form_title: '',
        form_activation_title: '',
        form_model: '',
        form_activation: '',
        form_field_model_placeholder: '',
        form_field_activation_placeholder: '',
        form_btn: '',
        form_btn_edit: '',
        form_selected: '',
        form_no_model: '',
        form_no_activation: '',
        form_no_terms: '',
        form_activation_logical: '',
        form_terms: '',
        register: '',
        select_category: '',
        select_brand: '',
        laundry: '',
        cooking: '',
        cooling: '',
        dishwasher: '',
        hoods: '',
        airconditioning: '',
        coffemachine: '',
        microwaves: '',
        hobs: '',
        select_model_title: '',
        select_model_text: '',
        select_model_code: '',
        select_model_modelcode: '',
        select_model_serial_number: '',
        select_model_product_number: '',
        select_model_industrial_code: '',
        select_activation_top: '',
        select_activation_title: '',
        select_activation_text: '',
        select_activation_stiker: '',
        select_activation_stiker_desc: '',
        select_activation_card: '',
        select_activation_card_text: '',
        cookie_label: 'Cookie Notice',
        privacy_label: 'Privacy Notice',
        cookie_url: '/cookies.html',
        privacy_url: 'https://www.whirlpool.eu/privacy-notice.html',
        landing: {
            title: 'REGISTER NOW TO ACTIVATE YOUR',
            subtitle: 'FREE',
            whitebox: 'PARTS GUARANTEE <sup>*</sup>',
            orangebox: 'REGISTRAZIONE NECESSARIA entro 60 giorni dalla data di acquisto dell\' elettrodomestico',
            callnow_label: '',
            callnow_number: '39800694545',
            link: 'CONTINUE YOUR JOURNEY ONLINE',
            advantages_title: 'WHY SHOULD YOU REGISTER NOW?',
            advantage_1: 'It will activate your 10 year free parts guarantee',
            advantage_2: 'We\'ll be in touch with the latest news and if there are any safety updates you need to know about',
            advantage_3: 'You\'ll have access to expert engineers during your guarantee',
            advantage_4: '',
            footer: '* Labour charges not included. Terms and conditions apply. If registered proof of purchase will not be needed. For the parts guarantee to be valid, the repairs need to be carried out by our engineers.<br>NB Lines are open 8am - 8pm, 365 days a year. Calls are free from all phones, whether mobile or landline.<br>Calls may be recorded and monitored for quality and training purposes.<br>** Hotpoint is part of Hotpoint UK Appliances Limited<br>Registered Office: Morley Way, Peterborough PE2 9JB. Registered in England and Wales.<br>Company No. 106725.',
            footer2: '',
            footer3: ''
        }

    },
    languageCountryMapping: {
        'ENGLISH': 'UK',
        'ITALIANO': 'IT',
        'FRENCH': 'FR',
        'SPANISH': 'ES'
    },
    nameCountryMapping: {
        'Albania': 'AL',
        'Austria': 'AT',
        'Belgium (FR)': 'BEFR',
        'Belgium (NL)': 'BENL',
        'Bosnia Herzegovina': 'BA',
        'Bulgaria': 'BG',
        'Croatia': 'HR',
        'Cyprus': 'CY',
        'Czechia': 'CZ',
        'Denmark': 'DK',
        'Estonia': 'EE',
        'Finland': 'FI',
        'France': 'FR',
        'Germany': 'DE',
        'Greece': 'GR',
        'Hungary': 'HU',
        'Italy': 'IT',
        'Latvia': 'LV',
        'Lithuania': 'LT',
        'Luxembourg': 'LU',
        'Moldova': 'MD',
        'Montenegro': 'ME',
        'Morocco': 'MA',
        'Netherlands': 'NL',
        'Norway': 'NO',
        'Poland': 'PL',
        'Portugal': 'PT',
        'Macedonia': 'MK',
        'Romania': 'RO',
        'Serbia': 'RS',
        'Slovakia': 'SK',
        'Slovenia': 'SI',
        'Spain': 'ES',
        'Sweden': 'SE',
        'Switzerland (DE)': 'CHDE',
        'Switzerland (FR)': 'CHFR',
        'Switzerland (IT)': 'CHIT',
        'Ukraine': 'UA'
    },
    brand: null,
    country: null,
    countryName: null,
    promo: null,
    sap_promo: null,
    language: null,
    productData: false,
    productSelected: null,
    commCodeSelected: null,
    skuSelected: null,
    prodRegLink: null,
    showPromoData: false,
    promoData: {
        years: null,
        first: null,
        second: null,
        third: null,
        fourth: null,
        note: null
    },
    promoComboData: {
        years: null,
        first: null,
        second: null,
        third: null,
        fourth: null,
    },
    countryList: null,
    showNoTranslationMessage: false,
    activationCodeQS: '',
    localeQS: '',
    isCodeOpened: null,
    isActivationCodeOpened: null,
    domain: null
    // otto12nc: [],
    //passion12nc: []
}

const mutations = {
    setCountry(state, country) {
        state.country = country;
        this.dispatch('getLabels')
    },
    setCountryName(state, name) {
        state.countryName = name.toUpperCase();
    },
    setLabels(state, values) {
        state.labels.values = values;
        if (state.domain == 'whplus' && state.country == 'IT') {
            state.labels.hero_first = 'REGISTRA IL TUO PRODOTTO';
            state.labels.hero_second = '';
            state.labels.hero_third = '';
        } else {
            state.labels.hero_first = values['hero_first'].toUpperCase();
            state.labels.hero_second = values['hero_second'].toUpperCase();
            state.labels.hero_third = values['hero_third'];
        }
        state.labels.meta_description = state.labels.hero_first[0].toUpperCase() + state.labels.hero_first.slice(1).toLowerCase() + " " + state.labels.hero_second.toLowerCase() + " " + state.labels.hero_third.toLowerCase();
        state.labels.form_title = values['form_title'].toUpperCase();
        state.labels.form_activation_title = values['form_activation_title'].toUpperCase();
        state.labels.form_model = values['form_model'];
        state.labels.form_activation = values['form_activation'];
        state.labels.form_field_model_placeholder = values['form_field_model_placeholder'];
        state.labels.form_field_activation_placeholder = values['form_field_activation_placeholder'];
        state.labels.form_btn = values['form_btn'];
        state.labels.form_btn_edit = values['form_btn_edit'];
        state.labels.form_selected = values['form_selected'].replace(/<br ?\/?>/g, "\n");
        state.labels.form_no_model = values['form_no_model'];
        state.labels.form_no_activation = values['form_no_activation'];
        state.labels.form_no_terms = values['form_no_terms'];
        state.labels.form_activation_logical = values['form_activation_logical'];
        state.labels.form_terms = values['form_terms'];
        state.labels.register = values['register'];
        state.labels.select_category = values['modal_select_category'].toUpperCase();
        state.labels.select_brand = values['modal_select_brand'].toUpperCase();
        state.labels.laundry = values['modal_section_laundry'].toUpperCase();
        state.labels.cooking = values['modal_section_cooking'].toUpperCase();
        state.labels.cooling = values['modal_section_cooling'].toUpperCase();
        state.labels.dishwasher = values['modal_section_dishwasher'].toUpperCase();
        state.labels.hoods = values['modal_section_hoods'].toUpperCase();
        state.labels.airconditioning = values['modal_section_airconditioning'].toUpperCase();
        state.labels.coffemachine = values['modal_section_coffemachine'].toUpperCase();
        state.labels.microwaves = values['modal_section_microwaves'].toUpperCase();
        state.labels.hobs = values['modal_section_hobs'].toUpperCase();
        state.labels.dryer = values['modal_section_dryer'].toUpperCase();
        state.labels.freezer = values['modal_section_freezer'].toUpperCase();
        state.labels.fridgefreezer = values['modal_section_fridgefreezer'].toUpperCase();
        state.labels.hobs = values['modal_section_hobs'].toUpperCase();
        state.labels.hoods = values['modal_section_hoods'].toUpperCase();
        state.labels.microwave = values['modal_section_microwave'].toUpperCase();
        state.labels.oven = values['modal_section_oven'].toUpperCase();
        state.labels.refrigerator = values['modal_section_refrigerator'].toUpperCase();
        state.labels.washerdryer = values['modal_section_washerdryer'].toUpperCase();
        state.labels.washingmachine = values['modal_section_washingmachine'].toUpperCase();
        state.labels.winecellar = values['modal_section_winecellar'].toUpperCase();
        state.labels.select_model_top = values['model_top'].toUpperCase();
        state.labels.select_model_title = values['model_title'];
        state.labels.select_model_text = values['model_text'];
        state.labels.select_model_code = values['model_code'];
        state.labels.select_model_serial_number = values['model_serial_number'];
        state.labels.select_model_modelcode = values['model_modelcode'];
        state.labels.select_model_product_number = values['model_product_number'];
        state.labels.select_model_industrial_code = values['model_industrial_code'];
        state.labels.select_activation_top = values['modal_activation_top'];
        state.labels.select_activation_title = values['modal_activation_title'];
        state.labels.select_activation_text = values['modal_activation_text'];
        state.labels.select_activation_stiker = values['modal_activation_sticker'];
        state.labels.select_activation_stiker_desc = values['modal_activation_sticker_text'];
        state.labels.select_activation_card = values['modal_activation_card'];
        state.labels.select_activation_card_desc = values['modal_activation_card_text'];
        if ((domainCheck.isWhPlus() || domainCheck.isRegister5() || domainCheck.isRegister10()) && state.country == 'IT') {
            state.labels.note = values['note_whplus'];
        } else {
            state.labels.note = values['note'];
        }
        state.labels.note_orig = values['note'];
        state.labels.cookie_label = values['cookie_label'];
        state.labels.privacy_label = values['privacy_label'];
        state.labels.cookie_url = values['cookie_url'];
        state.labels.privacy_url = values['privacy_url'];
        state.showNoTranslationMessage = false;

        document.querySelector('meta[name="description"]').setAttribute("content", state.labels.meta_description);

        state.labels.landing.title = values['landing_title']; //landing label example -> values is backend labels
        state.labels.landing.subtitle = values['landing_subtitle'];
        state.labels.landing.whitebox = values['landing_whitebox'];
        state.labels.landing.whitebox2 = values['landing_whitebox2'];
        state.labels.landing.orangebox = values['landing_orangebox'];
        state.labels.landing.callnow_label = values['landing_callnow_label'];
        state.labels.landing.callnow_label2 = values['landing_callnow_label2'];
        state.labels.landing.callnow_number = values['landing_callnow_number'];
        state.labels.landing.link = values['landing_link'];
        state.labels.landing.advantages_title = values['landing_advantages_title'];
        state.labels.landing.advantages_title2 = values['landing_advantages_title2'];
        state.labels.landing.advantage_1 = values['landing_advantage_1'];
        state.labels.landing.advantage_2 = values['landing_advantage_2'];
        state.labels.landing.advantage_3 = values['landing_advantage_3'];
        state.labels.landing.advantage_4 = values['landing_advantage_4'];
        state.labels.landing.footer = values['landing_footer'];
        state.labels.landing.footer2 = values['landing_footer2'];
        state.labels.landing.footer3 = values['landing_footer3'];

        // state.passion12nc =values['passion12nc'];
        // state.otto12nc = values['otto12nc'];
    },
    toggleShowProductData(state) {
        state.productData = !state.productData
    },
    setProductData(state, productData) {
        state.productSelected = productData
        state.commCodeSelected = productData['model']
        state.category = productData['category']
        state.skuSelected = productData['code']
        state.brand = productData['brand']
        state.promo = productData['promo']
        // state.country = productData['country']
        state.categoryCode = productData['categoryCode']
        state.mag = productData['mag']
        state.magtxt = productData['magtxt']
    },
    setProdRegLink(state, link) {
        state.prodRegLink = link
    },
    setPromoData(state, promoData) {
        state.promoData.years = promoData['years']
        if (promoData['first'].substring(0, 1) === '#') {
            state.promoData.first = state.labels.values[promoData['first'].substring(1, promoData['first'].length)]
        } else {
            state.promoData.first = promoData['first']
        }
        if (promoData['second'].substring(0, 1) === '#') {
            state.promoData.second = state.labels.values[promoData['second'].substring(1, promoData['second'].length)]
        } else {
            state.promoData.second = promoData['second']
        }
        if (promoData['third'].substring(0, 1) === '#') {
            state.promoData.third = state.labels.values[promoData['third'].substring(1, promoData['third'].length)]
        } else {
            state.promoData.third = promoData['third']
        }
        if (promoData['forth'] != undefined && promoData['forth'].substring(0, 1) === '#') {
            state.promoData.fourth = state.labels.values[promoData['forth'].substring(1, promoData['forth'].length)]
        } else {
            state.promoData.fourth = promoData['forth']
        }
        state.promoData.note = promoData['note-year']
        if (promoData['note-year'] !== undefined && promoData['note-year'] !== '') {
            state.labels.note = promoData['note-year'];
            state.labels.note_orig = promoData['note-year'];
        }
        if (state.domain == 'whplus') {
            if (promoData['note-year-whplus'] !== undefined && promoData['note-year-whplus'] !== '') {
                state.labels.note = promoData['note-year-whplus'];
                state.labels.note_orig = promoData['note-year-whplus'];
            }
        }
        state.showPromoData = true
    },
    setPromoComboData(state, promoData) {
        state.promoComboData.years = promoData['c-years']
        if (promoData['c-first'].substring(0, 1) === '#') {
            state.promoComboData.first = state.labels.values[promoData['c-first'].substring(1, promoData['c-first'].length)]
        } else {
            state.promoComboData.first = promoData['c-first']
        }
        if (promoData['c-second'].substring(0, 1) === '#') {
            state.promoComboData.second = state.labels.values[promoData['c-second'].substring(1, promoData['c-second'].length)]
        } else {
            state.promoComboData.second = promoData['c-second']
        }
        if (promoData['c-third'].substring(0, 1) === '#') {
            state.promoComboData.third = state.labels.values[promoData['c-third'].substring(1, promoData['c-third'].length)]
        } else {
            state.promoComboData.third = promoData['c-third']
        }
        if (promoData['c-forth'] != undefined && promoData['c-forth'].substring(0, 1) === '#') {
            state.promoComboData.fourth = state.labels.values[promoData['c-forth'].substring(1, promoData['c-forth'].length)]
        } else {
            state.promoComboData.fourth = promoData['c-forth']
        }
        state.showPromoComboData = true
    },
    hidePromoData(state) {
        state.showPromoData = false
        state.showPromoComboData = false
    },
    hideProductData(state) {
        state.productData = false
    },
    resetAll(state) {
        state.showPromoData = false
        state.showPromoComboData = false
        state.productData = false
        state.promoData.years = null
        state.promoData.first = null
        state.promoData.second = null
        state.promoData.third = null
        state.promoData.fourth = null
        state.promoData.note = null
        state.productSelected = null
        state.commCodeSelected = null
        state.skuSelected = null
        state.brand = null
        state.promo = null
        state.country = null
    },
    setCountryList(state, countryList) {
        state.countryList = countryList
    },
    showNoTranslationMessage(state) {
        state.showNoTranslationMessage = true
    },
    setCodeOpen(state, status) {
        state.isCodeOpened = status
    },
    setActivationCodeOpen(state, status) {
        state.isActivationCodeOpened = status
    },
    setPromo(state, promo) {
        state.promo = promo
    },
    setSapPromo(state, promo) {
        state.sap_promo = promo
    },
    updateNote(state, additional_note) {
        state.labels.note = state.labels.note_orig + additional_note
    },
    setDomain(state, domain) {
        state.domain = domain
    },
    setLoading(state, value) {
        state.isLoading = value
    }
}

const actions = {
    getLabels({ commit }) {
        const countryWithLang = countryMng.getMultiLangDefault(state.country);

        axios
            .get(`${process.env.VUE_APP_LAMBDA_URL}read?brand=GENERAL&country=${countryWithLang}`)
            .then((response) => {
                let configNode = response.data;

                if (configNode[1]) {
                    if (configNode[1]['hero_first']) {
                        commit('setLabels', configNode[1]);
                    } else {
                        console.log('no translation')
                        commit('showNoTranslationMessage');
                    }
                    commit('setCountryList', configNode[0]);
                } else {
                    console.log('no translation')
                    commit('showNoTranslationMessage');
                    commit('setCountryList', configNode[0]);
                    // commit('setCountry', 'UK');
                }
                commit('setLoading', false);
            }).catch(function (e) {
                console.log(e);
                commit('setLoading', false);
            });

    },
    setPromoDataAction({ commit }, promoData) {
        commit('setPromoData', promoData);
        if (promoData['c-years'] !== undefined) {
            commit('setPromoComboData', promoData);
        }
    }
}

export default createStore({
    state,
    mutations,
    actions
});
