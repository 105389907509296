<template>
  <VPopup ref="termsCondition" :title="popupTitle" :content="popupContent" />
</template>

<script>
import VPopup from "@/components/VPopup";
export default {
  name: "RegistrationData",
  components: {
    VPopup,
  },
  data() {
    return {
      popupContent: this.$store.state.promoData.note,
      popupTitle: "",
    };
  },
  watch: {
    "$store.state.promoData.note": function () {
      this.popupContent = this.$store.state.promoData.note;
    },
  },
  methods: {
    gotoModifyCode() {
      this.$store.commit("toggleShowProductData");
      this.$store.commit("hidePromoData");
    },
    gotoLink() {
      window.open(this.$store.state.prodRegLink, "_blank");
    },
    openPopupTerms() {
      this.$refs.termsCondition.open();
    },
  },
};
</script>

<style scoped lang="scss">
.container--registration-data {
  height: 60vw;
  display: flex;
  padding-bottom: 10vw;
  flex-direction: column;
  align-items: center;
  padding-top: 2vw;

  .form__items {
    padding-bottom: 0;
  }

  .action-container .btn button {
    width: auto;
  }

  .selected-data {
    justify-content: space-evenly;
    text-align: left;
    align-items: flex-start;
  }

  .selected-code {
    padding-top: 2vw;
    padding-bottom: 2vw;
    font-weight: 200;
    display: flex;
    flex-direction: column;
  }

  .comm-code {
    font-size: 5.5vw;
  }

  .sku-code {
    font-size: 4vw;
  }

  .confirm-btn.secondary {
    align-items: flex-start;
    justify-content: flex-end;
    padding-top: 2vw;
  }

  .action-container {
    display: flex;
    width: 100%;
    align-items: center;
    padding-top: 2vw;
    padding-bottom: 2vw;
    flex-direction: column;
  }

  .btn.active {
    display: block;
    margin-bottom: 1vw;
  }

  .popup-cta {
    text-align: center;
    color: white;
  }
}

@include desktop {
  .container--registration-data {
    height: 18vw;
    display: flex;
    padding-bottom: 10vw;

    .selected-data {
      justify-content: space-between;
    }

    .form__items {
      min-height: 12rem;
      //min-height: auto;
    }

    .comm-code {
      font-size: 3.5vw;
    }

    .sku-code {
      font-size: 1.5vw;
    }
  }
}
</style>
