export default {
  isWhPlus(environment = "*") {
    const whplus_domains = {
      prod: ["whplus.eu", "www.whplus.eu"],
      staging: ["whplus.staging.wpsandwatch.com"],
      dev: ["whplus.dev.wpsandwatch.com"],
    };
    whplus_domains["*"] = [
      ...whplus_domains["prod"],
      ...whplus_domains["staging"],
      ...whplus_domains["dev"],
    ];
    return whplus_domains[environment].includes(document.location.host);
  },
  isRegister5(environment = "*") {
    const register5_domains = {
      prod: ["register5.eu", "www.register5.eu"],
      staging: ["register5.staging.wpsandwatch.com"],
      dev: ["register5.dev.wpsandwatch.com"],
    };
    register5_domains["*"] = [
      ...register5_domains["prod"],
      ...register5_domains["staging"],
      ...register5_domains["dev"],
    ];
    return register5_domains[environment].includes(document.location.host);
  },
  isRegister10(environment = "*") {
    const register10_domains = {
      prod: ["register10.eu", "www.register10.eu"],
      staging: ["register10.staging.wpsandwatch.com"],
      dev: ["register10.dev.wpsandwatch.com"],
    };
    register10_domains["*"] = [
      ...register10_domains["prod"],
      ...register10_domains["staging"],
      ...register10_domains["dev"],
    ];
    return register10_domains[environment].includes(document.location.host);
  }
}