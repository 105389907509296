<template>
  <div class="footer">
    <a href="/cookies.html" target="_blank">{{ labels.cookie_label }}</a
    >&nbsp;-&nbsp;
    <a href="https://www.whirlpool.eu/privacy-notice.html" target="_blank">{{
      labels.privacy_label
    }}</a>
  </div>
</template>

<script>
export default {
  name: "VFooter",
  computed: {
    labels() {
      return this.$store.state.labels;
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  width: 100%;
  height: 20vw;
  position: absolute;
  bottom: 0;
  background-color: $nav-background;
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  a {
    color: white;
    text-decoration: none;
  }
}

@include desktop {
  .footer {
    height: 2vw;
  }
}
</style>
