<template>
  <p class="popup__text-title">
    {{ labels.select_model_title }}
  </p>
  <div class="code-selection">
    <div class="code-selection__container">
      <div class="container-category-list">
        <div class="category__items">
          <div
            class="category__item first"
            @click="openCategoryList()"
            @keydown.esc="closeCategoryList"
          >
            <div class="category">{{ this.categorySelected }}</div>
            <div class="category-after"></div>
          </div>
          <div class="category__list" v-show="showCategoryList">
            <ul>
              <li @click="chooseCategory('WASHERS')">
                {{ labels.washingmachine }}
              </li>
              <li @click="chooseCategory('WASHERDRYERS')">
                {{ labels.washerdryer }}
              </li>
              <li @click="chooseCategory('DRYERS')">
                {{ labels.dryer }}
              </li>
              <li @click="chooseCategory('COOKERS')">
                {{ labels.cooking }}
              </li>
              <li @click="chooseCategory('OVENS')">
                {{ labels.oven }}
              </li>
              <li @click="chooseCategory('FRIDGES')">
                {{ labels.fridgefreezer }}
              </li>
              <li @click="chooseCategory('FREEZERS')">
                {{ labels.freezer }}
              </li>
              <li @click="chooseCategory('DISHWASHINGS')">
                {{ labels.dishwasher }}
              </li>
              <li @click="chooseCategory('HOODS')">
                {{ labels.hoods }}
              </li>
              <li @click="chooseCategory('AIRCONDITIONING')">
                {{ labels.airconditioning }}
              </li>
              <li @click="chooseCategory('COFFEMACHINE')">
                {{ labels.coffemachine }}
              </li>
              <li @click="chooseCategory('MICROWAVES')">
                {{ labels.microwaves }}
              </li>
              <li @click="chooseCategory('HOBS')">
                {{ labels.hobs }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="code-selection__scroll">
        <div class="code-selection__main">
          <div class="activation-code-images">
            <div class="activation-code-image">
              <img src="@/assets/images/id_label_qr.png" />
            </div>
          </div>
          <div class="labels right">
            <div class="activation-code">
              <div class="activation-code text select-model-code">
                {{ labels.select_model_code }}
              </div>
            </div>
            <div class="activation-code">
              <div class="activation-code text select-model-industrial-code">
                {{ labels.select_model_industrial_code }}
              </div>
            </div>
            <div class="activation-code">
              <div class="activation-code text serial-number-code">
                {{ labels.select_model_serial_number }}
              </div>
            </div>
            <div class="activation-code">
              <div class="activation-code text number-code">
                {{ labels.select_model_product_number }}
              </div>
            </div>
          </div>
        </div>

        <div class="code-selection__images model" v-show="showImageCategory">
          <div class="category-code-image">
            <div class="category-code-image">
              <div class="category-code sticker-label">
                <div class="category-code sticker-title">
                  {{ labels.select_model_modelcode }}
                </div>
              </div>
            </div>
            <img
              src="@/assets/images/washers.png"
              v-show="categorySticker === 'washers'"
            />
            <!--lavatrice-->
            <img
              src="@/assets/images/washerdryers.png"
              v-show="categorySticker === 'washerdryers'"
            />
            <!--lavasciuga-->
            <img
              src="@/assets/images/dryers.png"
              v-show="categorySticker === 'dryers'"
            />
            <!--asciugatrice-->
            <img
              src="@/assets/images/cookers.png"
              v-show="categorySticker === 'cookers'"
            />
            <!--cucina-->
            <img
              src="@/assets/images/ovens.png"
              v-show="categorySticker === 'ovens'"
            />
            <!--forno-->
            <img
              src="@/assets/images/fridges.png"
              v-show="categorySticker === 'fridges'"
            />
            <!--Frigo congelatore-->
            <img
              src="@/assets/images/freezers.png"
              v-show="categorySticker === 'freezers'"
            />
            <!--Congelatore-->
            <img
              src="@/assets/images/dishwashings.png"
              v-show="categorySticker === 'dishwashings'"
            />
            <!--Lavastovigle-->
            <img
              src="@/assets/images/hood.png"
              v-show="categorySticker === 'hoods'"
            />
            <!--Cappa-->
            <img
              src="@/assets/images/airconditioning.png"
              v-show="categorySticker === 'airconditioning'"
            />
            <!--Climatizzatore-->
            <img
              src="@/assets/images/coffemachine.png"
              v-show="categorySticker === 'coffemachine'"
            />
            <!--Macchina del caffè-->
            <img
              src="@/assets/images/microwaves.png"
              v-show="categorySticker === 'microwaves'"
            />
            <!--Forno a microonde-->
            <img
              src="@/assets/images/hobs.png"
              v-show="categorySticker === 'hobs'"
            />
            <!--Piano cottura-->
            <!-- cappa, climatizzatore, macchina del caffè, microonde, piano_cottura -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModelSelection",
  data() {
    return {
      categorySelected: this.$store.state.labels.select_category,
      showCategoryList: false,
      showImageCategory: false,
      categorySticker: "",
    };
  },
  watch: {
    "$store.state.labels.select_category": function () {
      this.categorySelected = this.$store.state.labels.select_category;
    },
  },
  methods: {
    openCategoryList() {
      this.showCategoryList = !this.showCategoryList;
    },
    closeCategoryList() {
      this.showCategoryList = false;
    },
    chooseCategory(category) {
      let v = this;
      this.categorySelected = category;
      this.showCategoryList = false;
      this.showImageCategory = true;

      switch (category) {
        case "WASHERS":
          this.categorySelected = this.$store.state.labels.washingmachine;
          this.categorySticker = "washers";
          break;
        case "WASHERDRYERS":
          this.categorySelected = this.$store.state.labels.washerdryer;
          this.categorySticker = "washerdryers";
          break;
        case "DRYERS":
          this.categorySelected = this.$store.state.labels.dryer;
          this.categorySticker = "dryers";
          break;
        case "COOKERS":
          this.categorySelected = this.$store.state.labels.cooking;
          this.categorySticker = "cookers";
          break;
        case "OVENS":
          this.categorySelected = this.$store.state.labels.oven;
          this.categorySticker = "ovens";
          break;
        case "FRIDGES":
          this.categorySelected = this.$store.state.labels.fridgefreezer;
          this.categorySticker = "fridges";
          break;
        case "FREEZERS":
          this.categorySelected = this.$store.state.labels.freezer;
          this.categorySticker = "freezers";
          break;
        case "DISHWASHINGS":
          this.categorySelected = this.$store.state.labels.dishwasher;
          this.categorySticker = "dishwashings";
          break;
        case "HOODS":
          this.categorySelected = this.$store.state.labels.hoods;
          this.categorySticker = "hoods";
          break;
        case "AIRCONDITIONING":
          this.categorySelected = this.$store.state.labels.airconditioning;
          this.categorySticker = "airconditioning";
          break;
        case "COFFEMACHINE":
          this.categorySelected = this.$store.state.labels.coffemachine;
          this.categorySticker = "coffemachine";
          break;
        case "MICROWAVES":
          this.categorySelected = this.$store.state.labels.microwaves;
          this.categorySticker = "microwaves";
          break;
        case "HOBS":
          this.categorySelected = this.$store.state.labels.hobs;
          this.categorySticker = "hobs";
          break;
        default:
          this.categorySelected = this.$store.state.labels.select_category;
      }

      let bannerStatus = "Closed";
      if (v.$store.state.isCodeOpened) {
        bannerStatus = "Open";
      }
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "clickBanner",
          eventAction: "Where can I find the product code?",
          eventLabel: "Product category - " + category,
          bannerName: "Enter the model or Product code",
          bannerStatus: bannerStatus,
          country: v.$store.state.country,
        });
      } else {
        console.log("GTM not included");
      }
    },
  },
  computed: {
    labels() {
      return this.$store.state.labels;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./src/assets/scss/components/code-selection.scss";
</style>
