export default {
    getCountryFromReq() {
        var req = new XMLHttpRequest();
        req.open('GET', process.env.VUE_APP_LAMBDA_URL + 'country-code', false);
        req.send(null);
        if (JSON.parse(req.response)['CountryCode']) {
            return JSON.parse(req.response)['CountryCode']
        } else {
            return 'UK'
        }
        //return 'IE';
    },

    getLanguageFromCountry(languageCountryMapping, country) {
        for (var key in languageCountryMapping) {
            if (languageCountryMapping[key] === country)
                return key;
        }
        return 'NOT FOUND';
    },

    getNameFromCountry(nameCountryMapping, country) {
        for (var key in nameCountryMapping) {
            if (nameCountryMapping[key] === country)
                return key;
        }
        return 'NOT FOUND';
    },

    /**
     * Gets default language for multilanguage countries
     */
    getMultiLangDefault(country) {
        switch (country) {
            case 'BE':
            case 'CH':
                return country + 'FR'
            default:
                return country
        }
    }
}