<template>
  <div class="popup" v-show="show">
    <div class="popup__content">
      <span class="popup__close" @click="close()">X</span>
      <h2 class="popup__title">{{ title }}</h2>
      <slot><div class="popup__text" v-html="content"></div></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "VPopup",
  props: ["content", "title"],
  data() {
    return {
      show: false,
    };
  },
  methods: {
    open() {
      document.querySelector("body").style.height = "auto";
      document.querySelector("body").style.overflow = "hidden";
      this.show = true;
    },
    close() {
      document.querySelector("body").style.height = "auto";
      document.querySelector("body").style.overflow = "initial";
      this.show = false;
    },
    handleClickOutside(evt) {
      //Refactoring 2024-01-10 by GuglielmoB to avoid errors in browser console.
      const popupCta = document.querySelector('.popup-cta');
      const termsPopup = document.querySelector('.terms-popup');
      const popupContent = document.querySelector('.popup__content');
      const popupText = document.querySelector('.popup__text');

      if(
        !(popupCta && !popupCta.contains(evt.target)) &&
        !(termsPopup && !termsPopup.contains(evt.target)) &&
        !(popupContent && !popupContent.contains(evt.target)) &&
        !(popupContent && popupText.contains(evt.target))
      ){
        this.close();
      }
    },
    handleEscPressed(evt) {
      if (evt.keyCode === 27) {
        this.close();
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("keyup", this.handleEscPressed);
  },
};
</script>
<style lang="scss">
.popup {
  position: fixed;
  height: 95vh;
  width: 100vw;
  top: 0;
  left: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 999;

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-top: 4vw;
    flex-direction: column;
    background-color: white;
    position: absolute;
    top: 60%;
    left: 50%;
    width: 95vw;
    height: 75%;
    border-radius: 40px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
  }

  &__title {
    font-weight: bold;
    font-size: 5vw;
  }

  &__text-top {
    font-size: 1.3vw;
    width: 70%;
    font-weight: 600;
    text-align: left;
  }

  &__text-title {
    font-size: 1.3vw;
    width: 70%;
    margin: 0 auto 1.5vw;
    font-weight: 600;
    text-align: left;
  }

  &__text {
    font-size: 2vw;
    overflow: scroll;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
    text-align: left;
    b {
      text-align: center;
      font-weight: bold;
      font-size: 3.2vw;
    }

    ul {
      margin-left: 1vw;
    }

    a {
      color: black;
      font-weight: bold;
    }
  }

  &__close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    border: 1px solid #ee7003;
    border-radius: 20px;
    padding: 4px 8px;
    color: #ee7003;
    box-shadow: 0px 0px 4px 0px rgb(238 112 3 / 50%);
  }
}

@include smartphone {
  .popup {
    &__text-title {
      font-size: 3vw;
    }

    &__text-top {
      font-size: 3vw;
    }
  }
}

@include desktop {
  .popup {
    &__content {
      width: 50vw;
      height: 50%;
    }

    &__title {
      font-weight: bold;
      font-size: 4vw;
    }

    &__text {
      font-size: 1vw;
      b {
        font-weight: bold;
        font-size: 1.1vw;
      }
    }
  }
}

@include laptop {
  .popup {
    &__content {
      top: 70%;
    }
  }
}

@include xldesktop {
  .popup {
    &__content {
      top: 65%;
    }
  }
}
</style>
