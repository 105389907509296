<template>
  <div
    class="container container--form"
    v-show="!this.isActivationOpened"
    :class="{
      collapsible: this.isCodeInput,
      notcollapsible: !this.isCodeInput,
    }"
  >
    <div class="form__items">
      <div class="form__item title">
        <div
          class="form__item first"
          v-if="!this.elemSelected"
          v-html="this.$store.state.labels.form_title"
          :class="{ opened: this.isCodeOpened }"
        ></div>
        <div
          class="form__item first"
          v-if="this.elemSelected"
          v-html="this.$store.state.labels.form_selected"
        ></div>
        <div
          class="form__item selection"
          @click="openCode()"
          v-show="!this.elemSelected"
          :class="{ left: this.isCodeOpened }"
        ></div>
      </div>
      <div class="form__item second" v-show="this.isCodeOpened">
        <div class="input-field">
          <input
            type="text"
            :placeholder="this.$store.state.labels.form_field_model_placeholder"
            :class="{ active: this.elemSelected }"
            @input="onChange"
            v-model="search"
            @keydown.down="onArrowDown"
            @keydown.up="onArrowUp"
            @keydown.enter="onEnter"
          />
          <div class="m-page-loader m-page-loader--light" v-if="isLoading">
            <div class="m-page-loader__loader"></div>
          </div>
        </div>
        <div
          class="btn confirm-btn"
          :class="{ active: this.elemSelected || this.brandSelected }"
        >
          <button
            @click="gotoLink()"
            data-button="Register"
            class="register"
            :disabled="isModelDisabled || this.isButtonDisabled"
          >
            {{ labels.register }}
          </button>
        </div>
        <div class="form__item autocomplete" v-show="isOpen">
          <div>
            <ul>
              <li
                v-for="(result, i) in results"
                :key="i"
                :class="{ isActive: i === arrowCounter }"
                @click="setResult(result.code + ' - ' + result.model, result)"
              >
                {{ result.code }} - {{ result.model }}
              </li>
            </ul>
          </div>
        </div>

        <div class="form__error" v-show="this.error">Error!</div>
      </div>
      <div
        class="form__item third"
        v-show="!this.elemSelected && this.showBrandSelectList"
      >
        <div class="form__item no-model">
          {{ labels.form_no_model }}
        </div>
        <div class="container-brand-list">
          <div class="brand__items">
            <div
              class="brand__item first"
              @click="openBrandList()"
              @keydown.esc="closeBrandList"
            >
              <div class="brand" v-show="!this.brandSelected">
                {{ labels.select_brand }}
              </div>
              <div class="brand" v-show="this.brandSelected">
                {{ this.brandSelected }}
              </div>
            </div>
            <div class="brand__list" v-show="showBrandList">
              <ul>
                <li @click="chooseBrand('WHIRLPOOL')" data-item="Whirlpool">
                  WHIRLPOOL
                </li>
                <li
                  v-if="!invalidHPCountry"
                  @click="chooseBrand('HOTPOINT')"
                  data-item="Hotpoint"
                >
                  HOTPOINT
                </li>
                <li
                  v-if="!invalidBKCountry"
                  @click="chooseBrand('BAUKNECHT')"
                  data-item="Bauknecht"
                >
                  BAUKNECHT
                </li>
                <li
                  v-if="!invalidIDCountry"
                  @click="chooseBrand('INDESIT')"
                  data-item="Indesit"
                >
                  INDESIT
                </li>
                <li
                  v-if="!invalidPGCountry"
                  @click="chooseBrand('PRIVILEG')"
                  data-item="Privileg"
                >
                  PRIVILEG
                </li>
                <li
                  v-if="!invalidIGCountry"
                  @click="chooseBrand('IGNIS')"
                  data-item="Ignis"
                >
                  IGNIS
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        class="form__item popup-cta tc"
        v-show="this.elemSelected || this.brandSelected"
      >
        <span
          @click="openPopupTerms()"
          v-html="this.$store.state.labels.form_terms"
        ></span>
      </div>
      <div
        class="form__item popup-cta"
        @click="openModelPopup()"
        v-show="!this.elemSelected && this.showWhereIsModelLink"
      >
        {{ labels.form_model }}
      </div>
    </div>
  </div>
  <div
    class="container container--logical"
    v-show="
      !this.isActivationOpened && !this.isCodeOpened && this.isActivationVisible
    "
  >
    <div class="logical__items">
      {{ labels.form_activation_logical }}
    </div>
  </div>
  <div
    class="container container--form"
    v-show="!this.isCodeOpened && this.isActivationVisible"
    :class="{
      collapsible: this.isActivationInput,
      notcollapsible: !this.isActivationInput,
    }"
  >
    <div class="form__items">
      <div class="form__item title">
        <div
          class="form__item first"
          v-if="!this.elemSelected"
          v-html="this.$store.state.labels.form_activation_title"
          :class="{ opened: this.isActivationOpened }"
        ></div>
        <div
          class="form__item first"
          v-if="this.elemSelected"
          v-html="this.$store.state.labels.form_selected"
        ></div>
        <div
          class="form__item selection"
          @click="
            setBackOpenActivationButtonFlag();
            openActivation();
          "
          v-show="!this.elemSelected"
          :class="{ left: this.isActivationOpened }"
        ></div>
      </div>
      <div class="form__item second" v-show="this.isActivationOpened">
        <div class="input-field">
          <input
            type="text"
            :placeholder="
              this.$store.state.labels.form_field_activation_placeholder
            "
            :class="{ active: this.elemSelected }"
            @input="onActivationChange"
            v-model="codeSearch"
            @keydown.down="onArrowDown"
            @keydown.up="onArrowUp"
            @keydown.enter="onEnter"
            :maxlength="6"
          />
          <div class="m-page-loader m-page-loader--light" v-if="isLoading">
            <div class="m-page-loader__loader"></div>
          </div>
        </div>
        <div class="btn confirm-btn" :class="{ active: this.elemSelected }">
          <button
            @click="gotoLink()"
            data-button="Register"
            class="register"
            :disabled="isActivationDisabled || this.isButtonDisabled"
          >
            {{ labels.register }}
          </button>
        </div>
        <!-- <div class="form__item autocomplete" v-show="isCodeOpen">
          <div>
            <ul>
              <li
                  v-for="(result, i) in results" :key="i"
                  :class="{ 'isActive': i === arrowCounter }"
                  @click="setResult(result.code + ' - ' + result.model, result)">

                {{ result.code }} - {{ result.model }}
              </li>

            </ul>
          </div>
        </div> -->

        <div
          class="form__error"
          @click="
            openActivation();
            openCode();
          "
          v-show="this.errorActivation"
          style="cursor: pointer"
          v-html="this.$store.state.labels.form_no_activation"
        ></div>
      </div>

      <div class="form__item popup-cta tc" v-show="this.elemSelected">
        <span
          @click="openPopupTerms()"
          v-html="this.$store.state.labels.form_terms"
        ></span>
      </div>
      <div
        class="form__item popup-cta"
        @click="openActivationPopup()"
        v-show="!this.elemSelected && this.showWhereIsModelLink"
      >
        {{ labels.form_activation }}
      </div>
    </div>
  </div>
  <VPopup ref="popupModel" :title="popupTitle" :content="popupContent">
    <ModelSelection />
  </VPopup>
  <VPopup ref="popupActivation" :title="popupTitle" :content="popupContent">
    <ActivationCode />
  </VPopup>
  <VPopup ref="termsCondition" :title="popupTitle" :content="popupContentTC" />
  <VFooter />
</template>

<script>
import axios from "axios";
import VPopup from "@/components/VPopup";
import ModelSelection from "@/components/ModelSelection";
import ActivationCode from "@/components/ActivationCode";
import VFooter from "@/components/VFooter";
export default {
  name: "VForm",
  components: {
    VPopup,
    ModelSelection,
    ActivationCode,
    VFooter,
  },
  data() {
    return {
      n: 1,
      search: "",
      codeSearch: "",
      l: 0,
      isOpen: false,
      isCodeOpen: false,
      arrowCounter: 0,
      results: [],
      isLoading: false,
      country: "",
      elemSelected: null,
      formTitle: "",
      formModel: "",
      formFieldPlaceholder: "",
      formBtn: "",
      popupContent: "Popup Content",
      popupTitle: "",
      error: false,
      errorActivation: false,
      popupContentYTC: this.$store.state.promoData.note,
      popupContentTC: this.$store.state.labels.note,
      showWhereIsModelLink: true,
      showBrandSelectList: false,
      showBrandList: false,
      brandSelected: "",
      timeout: null,
      userSearch: "",
      isCodeConfirmed: false,
      isActivationConfirmed: false,
      openCodeInput: true,
      openActivationInput: true,
      isCodeInput: false,
      isActivationInput: false,
      isCodeOpened: false,
      isActivationOpened: false,
      activationCode: "",
      serialNumber: "",
      serializationCode: "",
      showBrandDiv: false,
      isButtonDisabled: true,
      isActivationVisible: false,
      backActivationCodeButtonClicked: false,
      hiddenCountries: {
        ID: ["LU"],
        BK: [
          "CZ",
          "SK",
          "HU",
          "RO",
          "EE",
          "FI",
          "LV",
          "LT",
          "GR",
          "HR",
          "UA",
          "BG",
          "RS",
          "LU",
          "CY",
          "ME",
          "YU",
          "SI",
          "MK",
          "MD",
          "XK",
          "BA",
        ],
        HP: [
          "CZ",
          "SK",
          "CHIT",
          "CHDE",
          "CHFR",
          "HU",
          "SE",
          "NO",
          "DK",
          "GR",
          "FI",
          "HR",
          "LU",
          "CY",
          "SI",
          "BA",
        ],
        IG: [
          "PL",
          "CZ",
          "SK",
          "CHIT",
          "CHDE",
          "CHFR",
          "HU",
          "RO",
          "SE",
          "NO",
          "DK",
          "EE",
          "FI",
          "LV",
          "LT",
          "GR",
          "HR",
          "UA",
          "BG",
          "RS",
          "LU",
          "CY",
          "ME",
          "YU",
          "SI",
          "MK",
          "MD",
          "XK",
          "BA",
        ],
        PG: [
          "PL",
          "CZ",
          "SK",
          "CHIT",
          "CHDE",
          "CHFR",
          "HU",
          "RO",
          "SE",
          "NO",
          "DK",
          "EE",
          "FI",
          "LV",
          "LT",
          "GR",
          "HR",
          "UA",
          "BG",
          "RS",
          "LU",
          "CY",
          "ME",
          "YU",
          "SI",
          "MK",
          "MD",
          "XK",
          "BA",
        ],
      },
    };
  },
  created() {
    this.isActivationVisible = !(
      this.$store.state.labels.form_activation_logical == null
    );
    this.$store.commit("setCodeOpen", false);
    this.$store.commit("setActivationCodeOpen", false);

    if (this.isWhPlus() || this.isRegister5() || this.isRegister10()) {
      this.$store.commit("setDomain", "whplus");
    } else {
      this.$store.commit("setDomain", "garanplus");
    }
  },
  computed: {
    upperCountry: function () {
      return this.$store.state.country?.toUpperCase();
    },
    isModelDisabled: function () {
      return !this.search;
    },
    isActivationDisabled: function () {
      return !this.codeSearch;
    },
    invalidBKCountry: function () {
      return this.hiddenCountries.BK.includes(this.upperCountry);
    },
    invalidIDCountry: function () {
      return this.hiddenCountries.ID.includes(this.upperCountry);
    },
    invalidIGCountry: function () {
      return this.hiddenCountries.IG.includes(this.upperCountry);
    },
    invalidHPCountry: function () {
      return this.hiddenCountries.HP.includes(this.upperCountry);
    },
    invalidPGCountry: function () {
      return this.hiddenCountries.PG.includes(this.upperCountry);
    },
    labels() {
      return this.$store.state.labels;
    },
  },
  watch: {
    "$store.state.productSelected": function () {
      this.elemSelected = this.$store.state.productSelected;
      this.isButtonDisabled = false;
      if (!this.$store.state.productSelected) {
        this.search = "";
        this.codeSearch = "";
      }
    },
    "$store.state.labels.note": function () {
      if (this.popupContentYTC !== null) {
        this.popupContentTC = this.$store.state.promoData.note;
      } else {
        this.popupContentTC = this.$store.state.labels.note;
      }
    },
    "$store.state.activationCodeQS": function () {
      this.activationCode = this.$store.state.activationCodeQS;
      this.codeSearch = this.activationCode;
      this.openActivation();
      this.onActivationChange();
    },
    "$store.state.localeQS": function () {
      this.openPopupTerms();
    },
    "$store.state.labels.form_activation_logical": function () {
      this.isActivationVisible = !(
        this.$store.state.labels.form_activation_logical == null
      );
    },
    "$store.state.isActivationOpened": function () {
      if (this.$store.state.isActivationOpened) {
        this.openActivation();
        this.onActivationChange();
      }
    },
  },
  methods: {
    onChange() {
      this.gotoModifyCode();
      this.elemSelected = null;
      this.brandSelected = null;
      this.isButtonDisabled = true;
      this.error = false;
      this.errorActivation = false;

      if (this.activationCode !== "") {
        this.search = this.activationCode;
      }

      if (this.search.length < 1) {
        this.n = 1;
      }

      clearTimeout(this.timeout);

      if (this.search.length >= this.l) {
        this.l = this.search.length;
        if (this.search.length > 3 * this.n) {
          let v = this;
          v.isLoading = true;
          let request =
            process.env.VUE_APP_LAMBDA_URL +
            "search?q=" +
            v.search +
            "&country=" +
            v.$store.state.country;
          this.timeout = setTimeout(function () {
            axios
              .get(request)
              .then(function (response) {
                let res = eval(response.data);
                console.log(res);
                v.isOpen = true;
                v.results = [];

                //  Close dropdown if brand is not supported in country
                const unsupportedCountry = v.invalidBrandCheck(res[0]);

                if (unsupportedCountry) {
                  v.isLoading = false;
                  v.isOpen = false;
                  v.showBrandSelectList = true;
                  v.showBrandDiv = true;
                } else {
                  for (var index = 0; index < res.length; index++) {
                    v.results.push({
                      codeF0: res[index].SKU_F0_Code,
                      code: res[index].SKU_Code,
                      //'promo': res[index].Sticker_Benefit,
                      country: res[index].Sticker_Destination,
                      brand: res[index].Brand,
                      category: res[index].Product_Category,
                      categoryCode: res[index].Product_Category_Code,
                      magtxt: res[index].MAG,
                      mag: res[index].MAG_Code,
                      model: res[index].Model,
                    });
                  }
                  v.isLoading = false;
                  if (v.activationCode !== "") {
                    v.setResult(
                      v.results[0].code + " - " + v.results[0].model,
                      v.results[0]
                    );
                  }
                }
              })
              .catch(function (e) {
                console.log(e);
                v.isOpen = false;
                v.isLoading = false;
                // v.showWhereIsModelLink = false;
                v.showBrandSelectList = true;
                v.showBrandDiv = true;
                if (v.activationCode !== "") {
                  v.showBrandSelectList = false;
                  v.showBrandDiv = false;
                  v.errorActivation = true;
                  v.search = null;
                }
              });
          }, 500);
          v.userSearch = v.search;
        }
      } else {
        this.l = this.search.length;
        if (this.l > 3) {
          // this.isOpen = true
        } else {
          this.n = 1;
          this.isOpen = false;
          this.isLoading = false;
        }
      }
    },

    onActivationChange() {
      this.gotoModifyCode();
      this.elemSelected = null;
      this.brandSelected = null;
      this.isButtonDisabled = true;
      this.error = false;
      this.errorActivation = false;
      if (this.codeSearch.length < 1) {
        this.n = 1;
      }

      clearTimeout(this.timeout);

      if (this.codeSearch.length > this.l) {
        this.l = this.codeSearch.length;
        if (
          this.codeSearch.length > 5 * this.n &&
          this.codeSearch.length < 7 * this.n
        ) {
          //console.log(this.$store.state.country)
          let v = this;
          v.isLoading = true;
          let request =
            process.env.VUE_APP_LAMBDA_URL +
            "product-data?activationcode=" +
            v.codeSearch;
          this.timeout = setTimeout(function () {
            axios
              .get(request)
              .then(function (response) {
                let res = eval(response.data);
                v.isCodeOpen = true;
                v.results = [];
                for (var index = 0; index < res.length; index++) {
                  v.results.push({
                    plant: res[index].Plant,
                    sku: res[index].SKU,
                    description: res[index].Description,
                    serialnumber: res[index].SerialNumber,
                    qrcode1: res[index].QRCode1,
                    qrcode2: res[index].QRCode2,
                    sticker: res[index].Sticker,
                    card: res[index].Card,
                    timestamp: res[index].Timestamp,
                  });
                }
                v.isLoading = false;
                if (v.results[0] !== null && v.results[0] !== undefined) {
                  v.serializationCode = v.results[0].sticker.trim();
                  v.activationCode = v.results[0].sku; // '869991596850';
                  v.serialNumber = v.results[0].serialnumber;
                  v.onChange();
                } else {
                  v.errorActivation = true;
                  v.isCodeOpen = false;
                  v.isLoading = false;
                }
              })
              .catch(function (e) {
                console.log(e);
                v.errorActivation = false;
                v.isCodeOpen = false;
                v.isLoading = false;
                // v.showWhereIsModelLink = false;
                // v.showBrandSelectList = true;
                if (v.activationCode !== "") {
                  v.errorActivation = true;
                  v.error = false;
                  v.search = null;
                }
              });
          }, 500);
          v.userSearch = v.codeSearch;
        }
      } else {
        this.l = this.codeSearch.length;
        if (this.l > 3) {
          // this.isCodeOpen = true
        } else {
          this.n = 1;
          this.isCodeOpen = false;
          this.isLoading = false;
        }
      }
    },

    onArrowDown() {
      if (this.arrowCounter < this.results.length) {
        this.arrowCounter = this.arrowCounter + 1;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter = this.arrowCounter - 1;
      }
    },
    onEnter() {
      if (this.results.length === 0) {
        document.querySelector(".input-field input").onkeypress = function (e) {
          var key = e.charCode || e.keyCode || 0;
          if (key === 13) {
            e.preventDefault();
          }
        };
      } else {
        this.search =
          this.results[this.arrowCounter].code +
          " - " +
          this.results[this.arrowCounter].model;
        this.isOpen = false;
        this.setResult(
          this.results[this.arrowCounter].code +
            " - " +
            this.results[this.arrowCounter].model,
          this.results[this.arrowCounter]
        );
        this.arrowCounter = -1;
      }
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    setResult(result, elemSelected) {
      let v = this;
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "clickBanner",
          eventAction: "Keyword - " + this.search,
          eventLabel: "Product code - " + result,
          bannerName: "Enter the model or Product code",
          bannerStatus: "Open",
          country: v.$store.state.country,
        });
      } else {
        console.log("GTM not included");
      }

      this.search = result;
      this.codeSearch = result;
      this.isOpen = false;
      this.isCodeOpen = false;
      this.elemSelected = elemSelected;
      this.isButtonDisabled = false;
      this.gotoProductRegistration();
    },

    gotoProductRegistration() {
      if (this.elemSelected || this.brandSelected) {
        let isWhPlus =
          this.isWhPlus() || this.isRegister5() || this.isRegister10();
        let brand = null;
        if (this.elemSelected) {
          this.$store.commit("setProductData", this.elemSelected);
          //console.log('elemento selezionato', this.elemSelected.code)
          brand = this.$store.state.brand.toUpperCase();
        } else {
          brand = this.brandSelected;
        }
        let v = this;
        //console.log('brand', brand);

        //READ PROMO FROM SAP
        var today = new Date();
        var month = today.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        var date = today.getFullYear() + "-" + month + "-" + today.getDate();

        let source = this.getSourceParameter();
        console.log(source);
        v.isLoading = true;
        console.log(this.elemSelected == null);

        if (this.elemSelected) {
          //Check if product is selected in list otherwise must be selected a brand
          let requestSap =
            process.env.VUE_APP_LAMBDA_URL +
            "read-from-sap?12nc=" +
            this.elemSelected.code +
            "&country=" +
            v.$store.state.country +
            "&reg_date=" +
            date +
            "&source=" +
            source;
          axios
            .get(requestSap)
            .then(function (response) {
              //console.log('READ FROM SAP');
              let retData = eval(response.data);
              console.log(retData.mapped);
              console.log(retData.sap);

              if (v.elemSelected) {
                v.$store.commit("setPromo", retData.mapped);
                v.$store.commit("setSapPromo", retData.sap);
              }

              let request =
                process.env.VUE_APP_LAMBDA_URL +
                "read?brand=" +
                brand +
                "&country=" +
                v.$store.state.country;
              axios
                .get(request)
                .then(function (response) {
                  console.log(brand);
                  console.log(response.data[1]);

                  v.$store.dispatch(
                    "setPromoDataAction",
                    response.data[1][v.$store.state.promo]
                  );

                  if (response.data[1]["additional_note"] !== undefined) {
                    if (isWhPlus) {
                      v.$store.commit(
                        "updateNote",
                        response.data[1]["additional_note"]
                      );
                    }
                  }

                  let webPageUrl =
                    response.data[1].webpage[
                      v.$store.state.envMapping[process.env.NODE_ENV]
                    ];
                  if (webPageUrl !== "") {
                    if (webPageUrl.indexOf("?") === -1) {
                      webPageUrl = webPageUrl + "?";
                    } else {
                      webPageUrl = webPageUrl + "&";
                    }

                    if (v.elemSelected) {
                      webPageUrl =
                        webPageUrl +
                        "PromoCode=" +
                        v.$store.state.sap_promo.replace(" ", "");
                    } else {
                      webPageUrl = webPageUrl + "PromoCode=";
                    }

                    if (v.serialNumber !== "" || v.serializationCode !== "") {
                      webPageUrl =
                        webPageUrl +
                        "&source=" +
                        source +
                        "&brand=" +
                        brand +
                        "&country=" +
                        v.$store.state.country +
                        "&sku=" +
                        v.$store.state.skuSelected +
                        "&model=" +
                        v.$store.state.commCodeSelected +
                        "&category=" +
                        v.$store.state.category +
                        "&category_value=" +
                        v.$store.state.categoryCode +
                        "&magtxt=" +
                        v.$store.state.magtxt +
                        "&mag=" +
                        v.$store.state.mag +
                        "&applianceserialnumber=" +
                        v.serialNumber +
                        "&serializationcode=" +
                        v.serializationCode +
                        "#register_machine";
                    } else {
                      webPageUrl =
                        webPageUrl +
                        "&source=" +
                        source +
                        "&brand=" +
                        brand +
                        "&country=" +
                        v.$store.state.country +
                        "&sku=" +
                        v.$store.state.skuSelected +
                        "&model=" +
                        v.$store.state.commCodeSelected +
                        "&category=" +
                        v.$store.state.category +
                        "&category_value=" +
                        v.$store.state.categoryCode +
                        "&magtxt=" +
                        v.$store.state.magtxt +
                        "&mag=" +
                        v.$store.state.mag +
                        "#register_machine";
                    }

                    // if (v.$store.state.country == "DE" && isWhPlus) {

                    //   let updateWebpage = null;
                    //   const otto12nc = JSON.parse(JSON.stringify(v.$store.state.otto12nc));
                    //   const passion12nc = JSON.parse(JSON.stringify(v.$store.state.passion12nc));

                    //   if (otto12nc.includes(v.$store.state.skuSelected)) {
                    //     updateWebpage = 'otto_webpage';
                    //   } else if (passion12nc.includes(v.$store.state.skuSelected)) {
                    //     updateWebpage = 'passion_webpage';
                    //   }
                    //   if (updateWebpage !== null) {
                    //     webPageUrl = response.data[1][updateWebpage][
                    //       v.$store.state.envMapping[process.env.NODE_ENV]
                    //     ];
                    //   }
                    // }

                    v.$store.commit("setProdRegLink", webPageUrl);
                  }
                  v.$store.commit("toggleShowProductData");
                  v.isLoading = false;
                })
                .catch(function (e) {
                  v.isLoading = false;
                  v.error = true;
                  v.errorActivation = false;
                  console.log(e);
                  if (v.activationCode !== "") {
                    v.errorActivation = true;
                    v.error = false;
                  }
                });
            })
            .catch(function (e) {
              v.isLoading = false;
              v.error = true;
              v.errorActivation = false;
              console.log(e);
              if (v.activationCode !== "") {
                v.errorActivation = true;
                v.error = false;
              }
            });
        } else {
          //Only brand is selected
          let request =
            process.env.VUE_APP_LAMBDA_URL +
            "read?brand=" +
            brand +
            "&country=" +
            v.$store.state.country;
          axios
            .get(request)
            .then(function (response) {
              console.log(brand);
              console.log(response.data[1]);
              if (v.elemSelected) {
                v.$store.dispatch(
                  "setPromoDataAction",
                  response.data[1][v.$store.state.promo]
                );
              }

              let webPageUrl =
                response.data[1].webpage[
                  v.$store.state.envMapping[process.env.NODE_ENV]
                ];
              if (webPageUrl !== "") {
                if (webPageUrl.indexOf("?") === -1) {
                  webPageUrl = webPageUrl + "?";
                } else {
                  webPageUrl = webPageUrl + "&";
                }

                if (v.elemSelected) {
                  webPageUrl =
                    webPageUrl +
                    "PromoCode=" +
                    v.$store.state.sap_promo.replace(" ", "");
                } else {
                  webPageUrl = webPageUrl + "PromoCode=";
                }

                if (v.serialNumber !== "" || v.serializationCode !== "") {
                  webPageUrl =
                    webPageUrl +
                    "&source=" +
                    source +
                    "&brand=" +
                    brand +
                    "&country=" +
                    v.$store.state.country +
                    "&sku=" +
                    v.$store.state.skuSelected +
                    "&model=" +
                    v.$store.state.commCodeSelected +
                    "&category=" +
                    v.$store.state.category +
                    "&category_value=" +
                    v.$store.state.categoryCode +
                    "&magtxt=" +
                    v.$store.state.magtxt +
                    "&mag=" +
                    v.$store.state.mag +
                    "&applianceserialnumber=" +
                    v.serialNumber +
                    "&serializationcode=" +
                    v.serializationCode +
                    "#register_machine";
                } else {
                  webPageUrl =
                    webPageUrl +
                    "&source=" +
                    source +
                    "&brand=" +
                    brand +
                    "&country=" +
                    v.$store.state.country +
                    "&sku=" +
                    v.$store.state.skuSelected +
                    "&model=" +
                    v.$store.state.commCodeSelected +
                    "&category=" +
                    v.$store.state.category +
                    "&category_value=" +
                    v.$store.state.categoryCode +
                    "&magtxt=" +
                    v.$store.state.magtxt +
                    "&mag=" +
                    v.$store.state.mag +
                    "#register_machine";
                }

                v.$store.commit("setProdRegLink", webPageUrl);
              }
              v.$store.commit("toggleShowProductData");
              v.isLoading = false;
            })
            .catch(function (e) {
              v.isLoading = false;
              v.error = true;
              v.errorActivation = false;
              console.log(e);
              if (v.activationCode !== "") {
                v.errorActivation = true;
                v.error = false;
              }
            });
        }
        //*****************************+
      }
    },

    openModelPopup() {
      let v = this;
      this.$refs.popupModel.open();

      let bannerStatus = "Closed";
      if (this.isCodeOpened) {
        bannerStatus = "Open";
      }
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "clickBanner",
          eventAction: "Where can I find the product code?",
          bannerName: "Enter the model or Product code",
          bannerStatus: bannerStatus,
          country: v.$store.state.country,
        });
      } else {
        console.log("GTM not included");
      }
    },

    openActivationPopup() {
      let v = this;
      let bannerStatus = "Closed";
      if (this.isActivationOpened) {
        bannerStatus = "Open";
      }
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "clickBanner",
          eventAction: "Where can I find the activation code?",
          bannerName: "Enter your Activation code",
          bannerStatus: bannerStatus,
          country: v.$store.state.country,
        });
      } else {
        console.log("GTM not included");
      }

      this.$refs.popupActivation.open();
    },

    gotoModifyCode() {
      this.$store.commit("toggleShowProductData");
      this.$store.commit("hidePromoData");
      this.showWhereIsModelLink = true;
      this.showBrandSelectList = false;
    },
    gotoLink() {
      let v = this;
      window.open(v.$store.state.prodRegLink, "_blank");
      v.sendGTMData();
    },
    openPopupTerms() {
      let v = this;
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "clickBanner",
          eventAction: "Terms and Conditions accepted",
          bannerName: "Enter the model or Product code",
          bannerStatus: "Open",
          country: v.$store.state.country,
        });
      } else {
        console.log("GTM not included");
      }
      this.$refs.termsCondition.open();
    },
    openBrandList() {
      this.showBrandList = !this.showBrandList;
    },
    closeBrandList() {
      this.showBrandList = false;
    },
    chooseBrand(brand) {
      let v = this;
      this.brandSelected = brand;
      this.showBrandList = false;
      this.isButtonDisabled = false;

      if (window.dataLayer) {
        window.dataLayer.push({
          event: "clickBanner",
          eventAction: "Select the brand",
          eventLabel: "Product brand - " + brand,
          bannerName: "Enter the model or Product code",
          bannerStatus: "Open",
          country: v.$store.state.country,
        });
      } else {
        console.log("GTM not included");
      }

      this.gotoProductRegistration();
    },
    openCode() {
      let v = this;

      let eventAction = "";
      let bannerStatus = "Closed";
      if (!this.isCodeOpened) {
        eventAction = "Click arrow open banner";
      } else {
        eventAction = "Click arrow close banner";
        bannerStatus = "Open";
      }
      if (!this.errorActivation) {
        if (window.dataLayer) {
          window.dataLayer.push({
            event: "clickBanner",
            eventAction: eventAction,
            bannerName: "Enter the model or Product code",
            bannerStatus: bannerStatus,
            country: v.$store.state.country,
          });
        } else {
          console.log("GTM not included");
        }
      } else {
        this.errorActivation = !this.errorActivation;
      }

      this.openActivationInput = !this.openActivationInput;
      this.isCodeInput = !this.isCodeInput;
      this.isCodeOpened = !this.isCodeOpened;
      v.$store.commit("setCodeOpen", this.isCodeOpened);
      if (this.showBrandDiv) {
        this.showBrandSelectList = !this.showBrandSelectList;
        this.brandSelected = null;
        this.isButtonDisabled = true;
      }
    },

    setBackOpenActivationButtonFlag() {
      this.backActivationCodeButtonClicked = this.isActivationOpened;
    },

    openActivation() {
      let v = this;
      let eventAction = "Click arrow open banner";
      let bannerStatus = "Closed";

      if (this.isActivationOpened) {
        if (this.errorActivation && !this.backActivationCodeButtonClicked) {
          eventAction = "Activation code not found, product code redirect";
        } else {
          eventAction = "Click arrow close banner";
        }
        bannerStatus = "Open";
      }
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "clickBanner",
          eventAction: eventAction,
          bannerName: "Enter your Activation code",
          bannerStatus: bannerStatus,
          country: v.$store.state.country,
        });
      } else {
        console.log("GTM not included");
      }

      this.openCodeInput = !this.openCodeInput;
      this.isActivationInput = !this.isActivationInput;
      this.isActivationOpened = !this.isActivationOpened;
      this.$store.commit("setActivationCodeOpen", this.isActivationOpened);
      this.backActivationCodeButtonClicked = false;
    },
    sendGTMData() {
      let v = this;
      let bannerName = "Enter the model or Product code";
      let eventLabel = "Product code - " + this.search;
      let eventAction = "";
      if (this.isActivationOpened) {
        bannerName = "Enter your Activation code";
        eventAction = "Product brand - " + this.$store.state.brand;
        eventLabel = "Activation code - " + this.search;
      } else {
        if (this.brandSelected) {
          eventLabel = "Product code not entered";
          eventAction = "Product brand - " + this.brandSelected;
        } else {
          eventAction = "Product brand - " + this.$store.state.brand;
        }
      }
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "requestSubmit",
          eventAction: eventAction,
          eventLabel: eventLabel,
          bannerName: bannerName,
          bannerStatus: "Open",
          country: v.$store.state.country,
        });
      } else {
        console.log("GTM not included");
      }
    },
    isDAndGCountry() {
      return [
        "AT",
        "BENL",
        "BEFR",
        "FR",
        "DE",
        "IT",
        "LU",
        "NL",
        "ES",
      ].includes(this.$store.state.country);
    },
    isWhPlus(environment = "*") {
      const whplus_domains = {
        prod: ["whplus.eu", "www.whplus.eu"],
        staging: ["whplus.staging.wpsandwatch.com"],
        dev: ["whplus.dev.wpsandwatch.com"],
      };
      whplus_domains["*"] = [
        ...whplus_domains["prod"],
        ...whplus_domains["staging"],
        ...whplus_domains["dev"],
      ];
      return whplus_domains[environment].includes(document.location.host);
    },
    isRegister5(environment = "*") {
      const register5_domains = {
        prod: ["register5.eu", "www.register5.eu"],
        staging: ["register5.staging.wpsandwatch.com"],
        dev: ["register5.dev.wpsandwatch.com"],
      };
      register5_domains["*"] = [
        ...register5_domains["prod"],
        ...register5_domains["staging"],
        ...register5_domains["dev"],
      ];
      return register5_domains[environment].includes(document.location.host);
    },
    isRegister10(environment = "*") {
      const register10_domains = {
        prod: ["register10.eu", "www.register10.eu"],
        staging: ["register10.staging.wpsandwatch.com"],
        dev: ["register10.dev.wpsandwatch.com"],
      };
      register10_domains["*"] = [
        ...register10_domains["prod"],
        ...register10_domains["staging"],
        ...register10_domains["dev"],
      ];
      return register10_domains[environment].includes(document.location.host);
    },
    // This check is based on [this spreadsheet rules](https://docs.google.com/spreadsheets/d/1La7npsx0qtz5zu3NblBaz9D27geaTppmkUkSuXi1kPI/edit#gid=1713374450)
    getSourceParameter() {
      const isItWhPlus =
        this.isWhPlus() || this.isRegister5() || this.isRegister10();
      console.log("country code:", this.$store.state.country);

      if (this.isDAndGCountry()) {
        if (isItWhPlus) {
          return "DGR2";
        } else {
          return "DGR1";
        }
      } else {
        if (isItWhPlus) {
          return "WHPS";
        } else {
          return "OREG";
        }
      }
    },
    /**
     * Checks if product registration for brand is not supported for country
     */
    invalidBrandCheck(product) {
      const brandCode = product.Brand_Code;
      const brandName = product.Brand;

      if (
        (this.invalidBKCountry &&
          (brandCode == "011" || brandName === "Bauknecht")) ||
        (this.invalidIDCountry &&
          (brandCode == "033" || brandName === "Indesit")) ||
        (this.invalidIGCountry &&
          (brandCode == "022" || brandName === "Ignis")) ||
        (this.invalidHPCountry &&
          (brandCode == "032" || brandName === "Hotpoint")) ||
        (this.invalidPGCountry &&
          (brandCode == "028" || brandName === "Privileg"))
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.container--form {
  height: 30vw;
  display: inline-flex;
  padding-bottom: 10vw;
  flex-direction: row;
  align-items: center;
  padding-top: 2vw;

  &.collapsible {
    width: auto;
  }
}

.container--logical {
  height: fit-content;
  width: fit-content;
  display: inline-flex;
  padding-bottom: 0vw;
  flex-direction: row;
  align-items: center;
  padding-top: 0vw;
}

.logical__items {
  position: relative;
  top: 0vw;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.form__items {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 70vw;
  height: auto;
  align-items: flex-start;
  padding-top: 1.5vw;
  padding-bottom: 1.5vw;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  border-radius: 30px;
  background-image: url("@/assets/images/lavatrici-sfondo.png");
  background-repeat: no-repeat;
  background-position: right 5% bottom 85%;
  background-size: 65vw;
  background-attachment: scroll;
}

.form__error {
  color: darkred;
  position: absolute;
  bottom: 5px;
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  margin: 0 auto;
  text-align: left;
}

.form__item {
  &.title {
    flex-direction: row;
    align-items: center;
    display: flex;
  }

  &.first {
    width: 90%;
    text-align: center;
    font-size: 4vw;
    font-weight: bold;
    line-height: 6vw;
    padding-right: 40px;
  }

  &.selection {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    font-size: 3.5vw;
    border: $menu-background;
    background: #ee7003;
    border-radius: 20px;
    padding: 0.6rem;
    box-shadow: 0px 0px 4px 0px $orange-text-color;

    &:after {
      font-family: "Barlow Condensed", serif;
      content: "\003E";
      color: white;
      display: block;
      margin-left: 8px;
      margin-right: 4px;
      font-size: 20px;
      transform: rotate(0deg) scale(0.75, 1);
    }

    &.down {
      &:after {
        margin-left: 8px;
        margin-right: 4px;
        transform: rotate(90deg) scale(0.75, 1);
      }
    }

    &.up {
      &:after {
        margin-left: 6px;
        margin-right: 6px;
        transform: rotate(270deg) scale(0.75, 1);
      }
    }

    &.left {
      &:after {
        margin-left: 6px;
        margin-right: 6px;
        transform: rotate(180deg) scale(0.75, 1);
      }
    }
  }

  &.second {
    width: 100%;
    text-align: center;
    font-size: 24vw;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  &.third {
    display: flex;
    width: 80%;
    font-size: 4vw;
    flex-wrap: wrap;
    /*background-color: #ee7003;*/
    /*margin-bottom: 2vw;*/
    justify-content: center;
    align-items: center;
    align-content: center;
    line-height: 6vw;
  }

  &.no-model {
    font-size: 1.2vw;
  }

  .container-brand-list {
    .brand__items {
      color: white;
      justify-content: center;
      position: relative;
      .brand__item {
        background-color: white;

        &.first {
          display: flex;
          justify-content: center;
          width: 70vw;
          padding: 10px 10px 10px 10px;
          border-radius: 20px;
          justify-content: center;
          margin-top: 10px;
          margin-bottom: 10px;
          color: #000;
          box-shadow: 0px 0px 6px 0px $orange-text-color;
          border: 1px solid $orange-text-color;
        }

        .brand {
          cursor: pointer;
          display: flex;
          align-items: center;
          font-weight: bold;
          &:after {
            font-family: "Barlow Condensed", serif;
            content: "\003E";
            color: $orange-text-color;
            display: block;
            margin-left: 10px;
            font-size: 25px;
            transform: rotate(90deg) scale(0.75, 1);
          }
        }
      }
      .brand__list {
        margin-top: 10px;
        background-color: white;
        border-radius: 20px;
        position: absolute;
        width: 75vw;
        color: #000;
        box-shadow: 0px 0px 6px 0px $orange-text-color;
        border: 1px solid $orange-text-color;
        ul {
          li {
            font-size: 5vw;
            list-style: none;
            padding-top: rem(7);
            padding-bottom: rem(7);
            font-weight: bold;
            cursor: pointer;
          }

          li:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .input-field {
    display: flex;
    align-items: center;
    position: relative;
    width: 90%;
    padding-top: 4vw;
    padding-bottom: 4vw;

    input {
      height: rem(35);
      border-radius: 30px;
      border: 1px solid $orange-text-color;
      padding-left: 20px;
      font-size: 4vw;
      font-weight: 200;
      padding-top: rem(5);
      padding-bottom: rem(5);
      letter-spacing: 1px;
      width: 100%;
      color: $input-text-color;
      box-shadow: 0 0 10px rgb(238 112 3 / 50%);

      &.active {
        color: $orange-text-color;
      }
    }
  }

  &.autocomplete {
    color: $input-text-color;
    text-align: center;
    font-size: 3vw;
    display: flex;
    align-items: flex-start;
    justify-content: left;
    border-radius: 20px;
    border: 1px solid $orange-text-color;
    background-color: white;
    position: absolute;
    padding-left: 3.5vw;
    padding-top: 1.5vw;
    padding-bottom: 1.5vw;
    width: 85%;
    top: 17.5vw;
    font-weight: normal;
    box-shadow: 0 0 10px rgb(238 112 3 / 20%);
    height: 100%;
    ul {
      text-align: left;
      height: 100%;

      li {
        list-style: none;
        cursor: pointer;
        padding-top: rem(4);
        padding-bottom: rem(4);
      }

      li:hover {
        color: $orange-text-color;
      }
    }

    .isActive {
      color: $orange-text-color;
      text-shadow: 0 0 rem(1) $orange-text-color;
    }

    > div {
      width: 100%;
      padding-right: rem(25);
      overflow: scroll;
      height: 100%;
      overflow: hidden;
    }
  }

  &.checkbox-cta {
    margin-right: 0.87rem;
    margin-left: auto;
    margin-bottom: -0.2rem;
    border: 1px solid #ee7003;
    box-sizing: border-box;
    padding: 10px;

    &:checked {
      background-image: url("@/assets/images/tick.png");
      background-size: 90%;
      background-position: center;
      background-repeat: no-repeat;
      &:after {
        visibility: visible;
      }
    }
  }
}

.confirm-btn {
  button {
    font-size: 4vw;
    height: 12.5vw;
    width: 50vw;
  }
}

@include desktop {
  .container--form {
    height: 14vw;
    display: inline-flex;
    padding-bottom: 10vw;
    width: 26vw;

    &.collapsible {
      width: auto;

      .title {
        margin-left: rem(4);
        margin-right: rem(30);
        flex-direction: row-reverse;
      }

      .second {
        margin-left: rem(50);
        margin-right: rem(50);
      }

      .popup-cta {
        margin-left: rem(32);
        margin-right: rem(32);
      }
    }

    &.notcollapsible {
      width: 24vw;
    }
  }

  .logical__items {
    top: 1.5vw;
  }

  .form__items {
    width: 50vw;
    background-size: 20vw;
  }

  .form__item {
    &.title {
      flex-direction: row;
      align-items: center;
      display: flex;
    }

    &.first {
      width: 80%;
      text-align: left;
      font-size: 1.2vw;
      line-height: 1.5vw;
      padding-right: 20px;
      padding-left: 20px;
      min-height: 2.8vw;

      &.opened {
        padding-right: 50px;
        padding-left: 0;
      }
    }

    &.selection {
      display: flex;
      align-items: center;
      cursor: pointer;
      text-align: center;
      font-weight: bold;
      font-size: 3.5vw;
      border: $menu-background;
      background: #ee7003;
      border-radius: 20px;
      padding: 0.6rem;
      margin-right: 0.5vw;

      &:after {
        font-family: "Barlow Condensed", serif;
        content: "\003E";
        color: white;
        display: block;
        margin-left: 8px;
        margin-right: 4px;
        font-size: 20px;
        transform: rotate(0deg) scale(0.75, 1);
      }
    }

    &.second {
      width: 90%;
      font-size: 4vw;
      flex-wrap: nowrap;
    }

    &.third {
      display: flex;
      width: 90%;
      font-size: 1.3vw;
      flex-wrap: nowrap;
      /*background-color: #ee7003;*/
      /*margin-bottom: 1vw;*/
      justify-content: space-between;
      align-items: center;
      line-height: 2vw;
      margin-left: rem(48);
      margin-right: rem(32);
    }

    &.no-model {
      font-size: 0.8vw;
    }

    .container-brand-list {
      margin-left: 10px;

      .brand__items {
        color: white;

        .brand__item {
          &.first {
            display: flex;
            justify-content: center;
            padding: 1px 10px 1px 10px;
            border-radius: 20px;
            width: 14.7vw;
          }

          .brand {
            font-size: 1vw;
          }
        }
        .brand__list {
          width: 16vw;

          ul {
            li {
              font-size: 1vw;
              list-style: none;
              padding-top: rem(0.1);
              padding-bottom: rem(0.1);
            }
          }
        }
      }
    }

    .input-field {
      width: 100%;
      padding-top: 2vw;
      padding-bottom: 2vw;
      margin-right: 1vw;
      input {
        width: 100%;
        height: 3vw;
        font-size: 1.3vw;
      }
    }

    &.autocomplete {
      left: 0;
      font-size: 1.3vw;
      height: auto;
      display: flex;
      justify-content: left;
      padding-left: 1.5vw;
      padding-top: 0.5vw;
      padding-bottom: 0.5vw;
      width: 74%;
      top: 6.5vw;
      min-height: 8.5rem;
    }

    &.checkbox-cta {
      margin-right: 0.87rem;
      margin-left: auto;
      margin-bottom: -0.2rem;
      border: 1px solid $orange-text-color;
      box-sizing: border-box;
      padding: 10px;

      &:checked {
        background-image: url("@/assets/images/tick.png");
        background-size: 90%;
        background-position: center;
        background-repeat: no-repeat;
        &:after {
          visibility: visible;
        }
      }
    }
  }

  .confirm-btn {
    button {
      font-size: 0.8vw;
      height: 3.5vw;
      width: 10vw;
      box-shadow: 0px 0px 4px 0px $orange-text-color;
      font-weight: bold;
      letter-spacing: 1px;

      &.register {
        font-size: 0.8vw;
        &:after {
          font-family: "Barlow Condensed", serif;
          content: "\003E";
          margin-left: 10px;
          font-size: 16px;
          transform: rotate(0deg) scale(0.75, 1);
        }
      }
    }
  }
}

@include desktop {
  .form__item {
    &.first {
      font-size: 0.9vw;
    }
  }
}

@include laptop {
  .form__item {
    &.first {
      font-size: 0.9vw;
    }
  }
}

@include xldesktop {
  .form__item {
    &.first {
      font-size: 1.2vw;
      padding-right: 0;
    }
  }
}

@include smartphone {
  .container--form {
    padding-bottom: 3vw;

    &.collapsible {
      width: auto;

      .title {
        margin-left: rem(10);
        flex-direction: row-reverse;
      }
    }
  }
  .form__items {
    padding: 4vw;
  }

  .form__item {
    &.first {
      font-size: 3.6vw;
      line-height: 4.6vw;
    }

    &.third {
      width: 100%;
      line-height: 3vw;
    }

    .confirm-btn {
      button {
        width: 62vw;
      }
    }

    &.no-model {
      font-size: 2.4vw;
    }

    .container-brand-list {
      .brand__items {
        .brand__item {
          &.first {
            width: 60vw;
          }

          .brand {
            font-size: 3.5vw;
          }
        }
      }

      .brand__list {
        .brand__list {
          width: 100%;

          ul {
            li {
              font-size: 3.5vw;
            }
          }
        }
      }
    }
  }
}
</style>
