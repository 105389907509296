<template>
  <p class="popup__text-top">
    {{ labels.select_activation_top }}
  </p>
  <p class="popup__text-title">
    {{ labels.select_activation_title }}
  </p>
  <div class="code-selection">
    <div class="code-selection__container">
      <div class="container-category-list">
        <div class="category__items">
          <div
            class="category__item first"
            @click="openCategoryList()"
            @keydown.esc="closeCategoryList"
          >
            <div class="category">{{ this.categorySelected }}</div>
            <div class="category-after"></div>
          </div>
          <div class="category__list" v-show="showCategoryList">
            <ul>
              <li @click="chooseCategory('WASHERS')">
                {{ labels.washingmachine }}
              </li>
              <!-- <li @click="chooseCategory('WASHERDRYERS');">
                {{ labels.washerdryer }}
              </li>
              <li @click="chooseCategory('DRYERS');">
                {{ labels.dryer }}
              </li>
              <li @click="chooseCategory('COOKERS');">
                {{ labels.cooking }}
              </li>
              <li @click="chooseCategory('OVENS');">
                {{ labels.oven }}
              </li>
              <li @click="chooseCategory('FRIDGES');">
                {{ labels.fridgefreezer }}
              </li>
              <li @click="chooseCategory('FREEZERS');">
                {{ labels.freezer }}
              </li>
              <li @click="chooseCategory('DISHWASHINGS');">
                {{ labels.dishwasher }}
              </li> -->
            </ul>
          </div>
        </div>
      </div>

      <div class="code-selection__scroll">
        <div class="code-selection__main">
          <div class="activation-code">
            <div class="activation-code title">
              {{ labels.form_field_activation_placeholder }}
            </div>
            <div class="activation-code text">
              {{ labels.select_activation_text }}
            </div>
          </div>
          <div class="activation-code-images">
            <div class="activation-code-image">
              <img src="@/assets/images/activation_code.png" />
            </div>
          </div>
        </div>

        <div class="code-selection__images" v-show="showImageCategory">
          <div class="category-code-image">
            <div class="category-code-image left">
              <div class="category-code sticker-label">
                <div class="category-code sticker-title">
                  {{ labels.select_activation_stiker }}
                </div>
                <div class="category-code sticker-text">
                  {{ labels.select_activation_stiker_desc }}
                </div>
              </div>
              <img src="@/assets/images/activation_left.png" />
            </div>
            <br />
            <div class="logica-text">
              {{ labels.form_activation_logical }}
            </div>
            <br />
            <div class="category-code-image right">
              <img src="@/assets/images/activation_right.png" />
              <div class="category-code sticker-label">
                <div class="category-code card-title">
                  {{ labels.select_activation_card }}
                </div>
                <div class="category-code card-text">
                  {{ labels.select_activation_card_desc }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivationCode",
  data() {
    return {
      categorySelected: this.$store.state.labels.select_category,
      showCategoryList: false,
      showImageCategory: false,
      categorySticker: "",
    };
  },
  watch: {
    "$store.state.labels.select_category": function () {
      this.categorySelected = this.$store.state.labels.select_category;
    },
  },
  methods: {
    openCategoryList() {
      this.showCategoryList = !this.showCategoryList;
    },
    closeCategoryList() {
      this.showCategoryList = false;
    },
    chooseCategory(category) {
      let v = this;
      this.categorySelected = category;
      this.showCategoryList = false;
      this.showImageCategory = true;

      switch (category) {
        case "WASHERS":
          this.categorySelected = this.$store.state.labels.washingmachine;
          this.categorySticker = "washers";
          break;
        /* case "WASHERDRYERS":
          this.categorySelected = this.$store.state.labels.washerdryer;
          this.categorySticker = 'washerdryers'
          break;
        case "DRYERS":
          this.categorySelected = this.$store.state.labels.dryer;
          this.categorySticker = 'dryers'
          break;
        case "COOKERS":
          this.categorySelected = this.$store.state.labels.cooking;
          this.categorySticker = 'cookers'
          break;
        case "OVENS":
          this.categorySelected = this.$store.state.labels.oven;
          this.categorySticker = 'ovens'
          break;
        case "FRIDGES":
          this.categorySelected = this.$store.state.labels.fridgefreezer;
          this.categorySticker = 'fridges'
          break;
        case "FREEZERS":
          this.categorySelected = this.$store.state.labels.freezer;
          this.categorySticker = 'freezers'
          break;
        case "DISHWASHINGS":
          this.categorySelected = this.$store.state.labels.dishwasher;
          this.categorySticker = 'dishwashings'
          break; */
        default:
          this.categorySelected = this.$store.state.labels.select_category;
      }

      let bannerStatus = "Closed";
      if (v.$store.state.isActivationCodeOpened) {
        bannerStatus = "Open";
      }
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "clickBanner",
          eventAction: "Where can I find the activation code?",
          eventLabel: "Product category - " + category,
          bannerName: "Enter your Activation code",
          bannerStatus: bannerStatus,
          country: v.$store.state.country,
        });
      } else {
        console.log("GTM not included");
      }
    },
  },
  computed: {
    labels() {
      return this.$store.state.labels;
    },
  },
};
</script>

<style scoped lang="scss">
@import "./src/assets/scss/components/code-selection.scss";
</style>
