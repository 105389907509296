<template>
  <div class="container container--language">
    <div class="language__items" v-show="!this.$store.state.showPromoData">
      <div
        class="language__item first"
        @click="openLangList()"
        @keydown.esc="closeLangList"
      >
        <img src="@/assets/images/world.png" />
        <div class="language">{{ this.$store.state.countryName }}</div>
      </div>
      <div class="language--list" v-show="showLangList">
        <ul>
          <li
            v-for="(country, i) in this.$store.state.countryList"
            :key="i"
            @click="setLang(country.key, country.country)"
          >
            {{ country.country }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VLanguage",
  data() {
    return {
      showLangList: false,
    };
  },
  created() {
    console.log(this.$store.state.country);
  },
  methods: {
    setLang(country, name) {
      this.showLangList = false;
      this.$store.commit("resetAll");
      this.$store.commit("setCountry", country);
      this.$store.commit("setCountryName", name);
    },
    openLangList() {
      this.showLangList = !this.showLangList;
    },
    closeLangList() {
      this.showLangList = false;
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.closeLangList();
      }
    },
    handleEscPressed(evt) {
      if (evt.keyCode === 27) {
        this.closeLangList();
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("keyup", this.handleEscPressed);
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped lang="scss">
.container--language {
  height: 15vw;
  display: flex;
  justify-content: center;
}

.language__items {
  display: flex;
  flex-direction: column;
  width: 90vw;
  height: auto;
  align-items: flex-end;
  justify-content: center;
  position: relative;
}

.language__item {
  cursor: pointer;

  &.first {
    padding-right: 5px;
    display: flex;
    align-items: center;

    img {
      width: 5vw;
    }
  }

  .language {
    display: flex;
    align-items: center;
    color: white;
    cursor: pointer;
    text-align: left;
    font-weight: bold;
    font-size: 3.5vw;
    border: $menu-background;
    padding-left: rem(10);
    padding-right: rem(20);

    &:after {
      font-family: "Barlow Condensed", serif;
      content: "\003E";
      color: $orange-text-color;
      display: block;
      margin-left: 10px;
      font-size: 20px;
      transform: rotate(90deg) scale(0.75, 1);
    }
  }
}

.language--list {
  position: absolute;
  background-color: white;
  color: $input-text-color;
  text-align: right;
  padding: 2vw 4vw 2vw;
  top: 8vh;
  border-radius: 5vw;
  -moz-border-radius: 5vw;
  z-index: 100;
  width: calc(100% - 7vw);
  box-shadow: 0 0 rem(20) rgba(0, 0, 0, 0.35);
  max-height: 40vh;
  overflow: scroll;

  ul {
    li {
      font-size: 4.5vw;
      list-style: none;
      padding-top: rem(8);
      padding-bottom: rem(8);
      cursor: pointer;
    }

    li:hover {
      color: $orange-text-color;
    }
  }
}

@include tablet {
  .language--list {
    top: 10vh;

    ul {
      li {
        font-size: 3.5vw;
        list-style: none;
        padding-top: rem(10);
        padding-bottom: rem(10);
        cursor: pointer;
      }

      li:hover {
        color: $orange-text-color;
      }
    }
  }
}

@include desktop {
  .container--language {
    height: auto;
    margin-top: 0;
    margin-bottom: 0;
  }

  .language__items {
    justify-content: flex-end;
    width: 50vw;
  }

  .language__item {
    &.first {
      img {
        width: 1.4vw;
      }
    }

    .language {
      font-size: 1vw;
      background-size: rem(14) rem(10);
      background-position: calc(100% - 0px);
      padding-left: 0.5vw;
      padding-right: 1.5vw;
    }
  }

  .language--list {
    width: auto;
    text-align: right;
    padding: 1vw 2vw 1vw;
    top: rem(32);
    border-radius: 1vw;
    -moz-border-radius: 1vw;

    ul {
      li {
        font-size: 0.9vw;
        padding-top: rem(5);
        padding-bottom: rem(5);
      }
    }
  }
}
</style>
