<template>
  <LandingPage
    v-if="showLanding"
    v-show="!isLoading"
    :countries="landingCountries"
    @show-home="showLanding = false"
  />
  <HomePage v-else />
</template>

<script>
import HomePage from "@/components/pages/HomePage";
import LandingPage from "@/components/pages/LandingPage";
import countryMng from "@/helper/country";

export default {
  name: "App",
  components: {
    HomePage,
    LandingPage,
  },
  data() {
    return {
      showLanding: false,
      landingCountries: ["IT", "DE", "FR", "NL", "BEFR", "BENL", "AT", "ES"],
      register10Domains: [
        "register10.eu",
        "www.register10.eu",
        "register10.staging.wpsandwatch.com",
        "register10.dev.wpsandwatch.com",
        "localhost:8080", //for testing purposes
      ],
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  methods: {
    checkLandingVisibility(country) {
      return (
        this.register10Domains.includes(document.location.host) &&
        this.landingCountries.includes(country)
      );
    },
  },
  beforeMount() {
    // Loading is set to false inside "setCountry" action
    this.$store.commit("setLoading", true);

    let country = "";

    if (window.location.href.includes("locale")) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      if (params.locale !== "") {
        country = params.locale.toUpperCase();
        this.$store.state.localeQS = country;
      }
    } else {
      country = countryMng.getCountryFromReq();
    }

    // Appends default language to multilang countries
    country = countryMng.getMultiLangDefault(country);

    const name = countryMng.getNameFromCountry(
      this.$store.state.nameCountryMapping,
      country
    );

    this.$store.commit("setCountry", country);
    this.$store.commit("setCountryName", name);

    this.showLanding = this.checkLandingVisibility(country);
  },
};
</script>

<style lang="scss">
/*@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;400;600;700&display=swap"');*/
@import url("https://fonts.googleapis.com/css2?family=Barlow&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;700&display=swap");
/*@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@200;400;600;700&display=swap');*/

// @import './assets/scss/main.scss';
</style>
