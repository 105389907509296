<template>
  <div class="container container--hero">
    <div
      class="hero"
      v-show="
        !this.$store.state.showPromoData &&
        !this.$store.state.showNoTranslationMessage
      "
    >
      <div class="txt__first_line">
        {{ labels.hero_first }}
      </div>
      <div class="txt__second_line">
        {{ labels.hero_second }}
      </div>
      <div class="txt__third_line">
        {{ labels.hero_third }}
      </div>
    </div>
    <div
      class="hero hero--finalData"
      v-show="
        this.$store.state.showPromoData &&
        !this.$store.state.showNoTranslationMessage
      "
    >
      <div class="txt__years">{{ this.$store.state.promoData.years }}</div>
      <div class="txt__first_line">{{ this.$store.state.promoData.first }}</div>
      <div class="txt__second_line">
        {{ this.$store.state.promoData.second }}
      </div>
      <div class="txt__third_line">{{ this.$store.state.promoData.third }}</div>
      <div class="txt__fouth_line">
        {{ this.$store.state.promoData.fourth }}
      </div>
    </div>
    <div
      class="hero hero--finalData hero--and"
      v-show="
        this.$store.state.showPromoComboData &&
        !this.$store.state.showNoTranslationMessage
      "
    >
      <span class="hero__and">&</span>
    </div>
    <div
      class="hero hero--finalData no-marginTop-mobile"
      v-show="
        this.$store.state.showPromoComboData &&
        !this.$store.state.showNoTranslationMessage
      "
    >
      <div class="txt__years">{{ this.$store.state.promoComboData.years }}</div>
      <div class="txt__first_line">
        {{ this.$store.state.promoComboData.first }}
      </div>
      <div class="txt__second_line">
        {{ this.$store.state.promoComboData.second }}
      </div>
      <div class="txt__third_line">
        {{ this.$store.state.promoComboData.third }}
      </div>
      <div class="txt__fouth_line">
        {{ this.$store.state.promoComboData.fourth }}
      </div>
    </div>
    <div
      class="hero hero--infoMessage"
      v-show="this.$store.state.showNoTranslationMessage"
    >
      <div class="txt__info-message">
        If your country is not on the list that means that no promotion is
        available at the moment
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VHero",
  computed: {
    labels() {
      return this.$store.state.labels;
    },
  },
};
</script>

<style scoped lang="scss">
.container--hero {
  height: auto;
}

.txt__years {
  font-weight: bold;
  font-size: 6vw;
  color: $orange-text-color;
}

.txt__first_line {
  font-weight: bold;
  font-size: 6vw;
  color: $orange-text-color;
}

.txt__second_line {
  font-weight: bold;
  font-size: 6vw;
  color: white;
}

.txt__third_line {
  font-size: 5vw;
  color: white;
}

.hero {
  display: flex;
  flex-direction: column;
  width: 80vw;
  margin: 5vw auto 0;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border-radius: 20px;
}

.hero--finalData {
  .txt__first_line {
    font-weight: bold;
    font-size: 6vw;
    color: black;
  }

  .txt__second_line {
    font-weight: bold;
    font-size: 6vw;
    color: $orange-text-color;
  }

  .txt__third_line {
    font-size: 5vw;
  }

  .txt__fouth_line {
    font-size: 5vw;
    color: $orange-text-color;
  }
}

.hero--infoMessage {
  background-color: rgba(255, 255, 255, 0.85);
  padding-left: 10px;
  padding-right: 10px;
}

.hero--and {
  background-color: white;
  opacity: 1;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100px;
}

.no-marginTop-mobile {
  margin-top: 0;
}

.hero__and {
  font-size: 6vw;
  color: $orange-text-color;
  padding-left: 35px;
  padding-right: 35px;
  margin-top: 0;
}

@include desktop {
  .container--hero {
    height: auto;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
  }

  .hero {
    width: 50vw;
    margin: 2.5vw auto 0;
    line-height: 32px;
  }

  .no-marginTop-mobile {
    margin: 2.5vw 25px 0;
  }

  .hero--finalData {
    margin-left: 25px;
    margin-right: 25px;
  }

  .txt__first_line {
    font-size: 2.2vw;
  }

  .txt__second_line {
    font-weight: bold;
    font-size: 2.2vw;
  }

  .txt__third_line {
    font-size: 2vw;
  }

  .txt__years {
    font-size: 6vw;
    line-height: 5.5vw;
  }

  .hero--finalData {
    width: 35vw;

    .txt__first_line {
      font-weight: bold;
      font-size: 1.8vw;
      color: black;
    }

    .txt__second_line {
      font-weight: bold;
      font-size: 1.8vw;
      color: $orange-text-color;
    }

    .txt__third_line {
      font-weight: bold;
      font-size: 1.8vw;
    }

    .txt__fouth_line {
      font-weight: bold;
      font-size: 1.8vw;
      color: $orange-text-color;
    }
  }

  .hero--and {
    background-color: white;
    opacity: 1;
    position: absolute;
    width: auto;
    margin-top: 0;
    height: 100px;
    z-index: 9;
    left: initial;
    top: calc(50% - 60px);
  }

  .hero__and {
    margin-top: 2vw;
  }
}

@include laptop {
  .txt__first_line {
    font-size: 32px;
  }

  .txt__second_line {
    font-size: 32px;
  }

  .txt__third_line {
    font-size: 28px;
  }

  .hero--finalData {
    .txt__first_line {
      font-size: 26px;
    }

    .txt__second_line {
      font-size: 26px;
    }

    .txt__third_line {
      font-size: 26px;
    }

    .txt__fouth_line {
      font-size: 26px;
    }
  }
}
</style>
