<template>
  <section class="landing-page" v-bind:id="`landing-page_${country}`">
    <div class="landing-page__section landing-page__header">
      <span class="landing-page__header--title" v-html="labels.title"></span>
      <img
        v-if="country === 'IT'"
        src="@/assets/images/landing/10_years_IT.png"
      />
      <img
        v-else-if="country === 'DE'"
        src="@/assets/images/landing/10_years_DE.png"
      />
      <img
        v-else-if="country === 'FR'"
        src="@/assets/images/landing/10_years_FR.png"
      />
      <img
        v-else-if="country === 'NL'"
        src="@/assets/images/landing/10_years_NL.png"
      />
      <img
        v-else-if="country === 'AT'"
        src="@/assets/images/landing/10_years_DE.png"
      />
      <img
        v-else-if="country === 'ES'"
        src="@/assets/images/landing/10_years_ES.png"
      />
      <img
        v-else-if="['BE', 'BEFR', 'BENL'].includes(country)"
        src="@/assets/images/landing/10_years_BE.png"
      />
      <div
        class="landing-page__header--subtitle"
        v-html="labels.subtitle"
      ></div>
      <div class="landing-page__header--white-box" v-bind:lang="`${country}`">
        <span>{{ labels.whitebox }}</span>
        <span v-if="['BE', 'BEFR', 'BENL'].includes(country)">{{ labels.whitebox2 }}</span>
      </div>
      <div class="landing-page__header--orange-box">
        <span v-html="labels.orangebox"></span>
      </div>
    </div>

    <!-- CTA SECTION -->
    <div class="landing-page__section landing-page__cta">
      <a class="landing-page__cta--tel" :href="'tel:' + labels.callnow_number">
        <div class="landing-page__cta--call-now">
          <div class="landing-page__cta--call-now-icon">
            <img src="@/assets/images/landing/phone.svg" />
          </div>
          <div class="landing-page__cta--call-now-text">
            <span class="landing-page__cta--call-now-text--label">{{ labels.callnow_label }}</span>
            <span v-if="['BE', 'BEFR', 'BENL'].includes(country)"  class="landing-page__cta--call-now-text--label">{{ labels.callnow_label2 }}</span>
            <span class="landing-page__cta--call-now-text--phone" v-if="country !== 'FR'">{{ labels.callnow_number }}</span>
            <div class="landing-page__cta--call-now-text--fr" v-if="country === 'FR'">
              <span class="landing-page__cta--call-now-text--fr-phone">{{ labels.callnow_number }}</span>
              <span class="landing-page__cta--call-now-text--fr-note">Service & appel gratuits</span>
            </div>
          </div>
        </div>
      </a>
      <a class="landing-page__cta--register" @click="$emit('showHome')">{{
        labels.link
      }}</a>
      <div class="landing-page__cta--banner">
        <img src="@/assets/images/landing/landing-banner.jpg" />
      </div>
    </div>

    <div class="landing-page__section landing-page__advantages">
      <div class="landing-page__advantages--text">
        <div class="landing-page__advantages--text--title">
          {{ labels.advantages_title }}
        </div>
        <div v-if="['BE', 'BEFR', 'BENL'].includes(country)" class="landing-page__advantages--text--title">
          {{ labels.advantages_title2 }}
        </div>
        <div class="landing-page__advantages--text-points">
          <div class="landing-page__advantages--text-point">
            <img src="@/assets/images/landing/tick.png" />
            <span v-html="labels.advantage_1"></span>
          </div>
          <div class="landing-page__advantages--text-point">
            <img src="@/assets/images/landing/tick.png" />
            <span v-html="labels.advantage_2"></span>
          </div>
          <div class="landing-page__advantages--text-point">
            <img src="@/assets/images/landing/tick.png" />
            <span v-html="labels.advantage_3"></span>
          </div>
          <div
            v-if="country === 'FR'"
            class="landing-page__advantages--text-point"
          >
            <img src="@/assets/images/landing/tick.png" />
            <span>{{ labels.advantage_4 }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="landing-page__section landing-page__footer">
      <p><span v-html="labels.footer"></span>
      <span><a @click="$emit('showHome')">www.register10.eu</a></span>
      <span v-html="labels.footer2"></span>
      <span v-if="['BE', 'BEFR', 'BENL'].includes(country)"><a @click="$emit('showHome')">www.register10.eu</a></span>
      <span v-if="['BE', 'BEFR', 'BENL'].includes(country)" v-html="labels.footer3"></span>
      </p>
    </div>
  <VFooter />
  </section>

</template>

<script>
import VFooter from "@/components/VFooter";
export default {
  name: "LandingPage",
  components: {
    VFooter
  },
  props: {
    countries: Array,
  },
  data() {
    return {};
  },
  computed: {
    labels() {
      return this.$store.state.labels.landing;
    },
    country() {
      return this.countries.includes(this.$store.state.country)
        ? this.$store.state.country
        : "UK"; //fallback for unsupported countries (show english icons and labels)
    },
    // tenYearsImgSrc() {
    //   return `@/assets/images/landing/10_years_${this.country}.png`;
    // },
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.landing-page {
  &__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
  }

  &__header {
    background-color: $nav-background;

    &--title {
      color: $landing-white;
      font-size: rem(16);
      margin-top: rem(30);
      margin-bottom: rem(10);
    }

    &--subtitle {
      color: $landing-white;
      font-size: rem(16);
      margin: rem(10) 0;
    }

    &--white-box {
      background-color: $landing-white;
      padding: rem(5) rem(10);
      text-align: center;
      margin: 0 rem(25) rem(25) rem(25);
      color: $landing-orange;
      font-size: rem(37);
      font-weight: bold;
      display: flex;
      flex-direction: column;

      @include desktop {
        max-width: 65%;
        padding: rem(5) rem(25);
      }

      span:after {
        display: inline-block;
        content: "*";
        font-size: 15px;
        vertical-align: top;
        margin-top: 5px;
        color: $landing-orange;
      }

      &[lang="DE"]  {
        word-wrap: break-word;
        hyphens: auto;
      }
    }

    &--orange-box {
      background-color: $landing-orange;
      padding: rem(5);
      text-align: center;
      margin-top: rem(15);
      width: 100%;

      span {
        color: $landing-white;
        font-size: rem(16);
      }
    }

    img {
      height: 160px;
    }
  }

  &__cta {
    @include desktop {
      background-image: url("@/assets/images/landing/landing-banner.jpg");
      background-size: cover;
      height: rem(560);
      padding: 0 rem(50);
      align-items: flex-start;
      justify-content: center;
    }

    &--tel {
      text-decoration: none;
      padding: rem(10);
    }

    &--call-now {
      color: $landing-white;
      background-color: $nav-background;
      padding: rem(20);
      border-radius: rem(25);
      margin-top: rem(30);
      display: flex;

      @include desktop {
        max-width: rem(500);
      }

      &-icon {
        display: flex;
        img {
          width: rem(60);
          margin-right: rem(20);

          @include desktop {
            width: rem(135);
          }
        }
      }

      &-text {
        text-align: left;

        &--label {
          font-size: rem(19);
          &:after {
            display: inline-block;
            content: "**";
            font-size: 15px;
            vertical-align: top;
            margin-top: 5px;
            color: $landing-white;
          }
        }

        &--phone {
          display: block;
          font-size: rem(33);
        }

        @include desktop {
          display: flex;
          flex-direction: column;
          justify-content: center;
          &--label {
            font-size: rem(25);
          }

          &--phone {
            font-size: rem(38);
          }
        }
      }
    }

    &--register {
      color: $nav-background;
      font-size: rem(14);
      margin: rem(20) 0;
      text-decoration: underline;
      cursor: pointer;

      @include desktop {
        color: $landing-white;
      }
    }

    &--banner {
      img {
        width: 100%;
        height: auto;
      }
      @include desktop {
        display: none;
      }
    }
  }

  &__advantages {
    @include desktop {
      margin: rem(100) 0;
    }

    &--text {
      font-size: rem(14);
      margin: rem(20);
      max-width: 65%;

      @include desktop {
        max-width: none;
      }

      img {
        width: rem(25);
        margin: rem(15) auto 0;
        display: block;
      }

      @include desktop {
        &-points {
          display: flex;
          width: rem(1400);
          justify-content: space-evenly;
        }

        &-point {
          width: rem(350);
          max-width: rem(350);
          padding: rem(20);
        }
      }
    }
  }

  &__footer {
    background-color: $landing-grey;
    padding: rem(35);

    span {
      font-size: rem(11);
      text-align: left;
      color: #000;

      a,
      &::v-deep a {
        color: #000;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .footer {
    position: relative;
  }
}
#landing-page_FR {
  // .landing-page__cta--call-now-text :before {
  //   display: none;
  // }
  .landing-page__cta--call-now-text--fr {
    width: fit-content;
    &-phone {
      background: $landing-white;
      color: #69b329;
      font-size: rem(20);
      padding: rem(5);
      position: relative;
      display: block;

      &:after {
        content:'';
        position: absolute;
        top: 100%;
        left: rem(5);
        width: 0;
        height: 0;
        border-top: solid rem(7) $landing-white;
        border-left: solid rem(7) transparent;
        border-right: solid rem(7) transparent;
      }
    }

    &-note {
      color: $landing-white;
      background: #69b329;
      padding: rem(5) rem(20);
      font-size: rem(11);
      display: block;
    }
  }
}
</style>
