<template>
  <section class="home-page">
    <VNav />
    <VHero />
    <VLanguage />
    <VForm />
    <RegistrationData />
  </section>
</template>

<script>
import VNav from "@/components/VNav.vue";
import VHero from "@/components/VHero.vue";
import VLanguage from "@/components/VLanguage.vue";
import VForm from "@/components/VForm.vue";
import RegistrationData from "@/components/RegistrationData";

export default {
  name: "HomePage",
  components: {
    VNav,
    VHero,
    VLanguage,
    VForm,
    RegistrationData,
  },
  mounted() {
    this.$store.state.isActivationOpened = false;
    if (window.location.href.includes("activationCode")) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      this.$store.state.activationCodeQS = params.activationCode;
    }

    if (window.location.href.includes("activate")) {
      this.$store.state.isActivationOpened = true;
    } else {
      this.$store.state.isActivationOpened = false;
    }
  },
};
</script>

<style lang="scss">
.home-page {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  background-image: url("@/assets/images/gp_background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
</style>
